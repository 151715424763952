import PhoneInput from 'react-phone-input-2';
import './phoneNumber.css';
import 'react-phone-input-2/lib/style.css'
const PhoneInputComponent: React.FC<MyComponentProps> = ({ value, inputProps, onBlur, onChange, onFocus }) => {
	return (
		<PhoneInput
			country={'in'}
			value={value}
			inputProps={inputProps}
			onBlur={onBlur}
			inputClass={value ? 'input-value' : ""}
			onFocus={onFocus}
			onChange={onChange}
			countryCodeEditable={false}
		/>
	);
};
export default PhoneInputComponent;
interface InputProps {
	name?: string;
	autoFocus?: boolean;
	required?: boolean;
	placeholder?: string;
}

interface MyComponentProps {
	value: string;
	inputProps?: InputProps;
	onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
	onChange?: (value: string, data: CountryData | {}, event: React.ChangeEvent<HTMLInputElement>, formattedValue: string) => void;
	onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
}

export interface CountryData {
	name: string;
	dialCode: string;
	countryCode: string;
	format: string;
}