import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import PhoneInputComponent from '../../components/PhoneInputComponent';

import Banner1 from '../../assets/img/banner1.jpg';
import GetAccess1 from '../../assets/img/get1.png';
import GetAccess2 from '../../assets/img/get2.png';
import Showcaseimg1 from '../../assets/img/work1.png';
import TVimg1 from '../../assets/img/tv-img.png';
import Icon from '../../assets/img/d-icon.png';
import Icon1 from '../../assets/img/icon1.png';
import Icon2 from '../../assets/img/d-icon2.png';
import testiImg from '../../assets/img/testi-img.png';
import work1 from '../../assets/img/work1.png';
import ptvBanner from '../../assets/img/ptv-banner.png';
import you3 from '../../assets/img/you3.png';
import you4 from '../../assets/img/you4.png';
import you5 from '../../assets/img/you5.png';
import you6 from '../../assets/img/you6.png';
import box2 from '../../assets/img/box2.png';
import box3 from '../../assets/img/box3.png';
import box4 from '../../assets/img/box4.png';
import banner2 from '../../assets/img/banner2.png';
import banner3 from '../../assets/img/banner3.png';
import Pricing from '../../components/pricing';
import android_tv from '../../assets/img/android_tv.png';
import { Link } from 'react-scroll';
import key1 from '../../assets/img/key1.png';
import key2 from '../../assets/img/key2.png';
import key3 from '../../assets/img/key3.png';
import key4 from '../../assets/img/key4.png';
import key5 from '../../assets/img/key5.png';

export default function LandingPage() {
	const [formData, setFormData] = useState({
		name: '',
		lastname: '',
		phoneNumber: '+91',
		email: '',
		message: '',
	});
	const [overviewData, setOverviewData] = useState<any>({
		projectShared: ['0', 'k'],
		filmMakers: ['0', 'K'],
		dataUploaded: ['0', 'GB'],
		clientServed: ['0', 'k'],
	});

	const fetchOverviewData = async () => {
		try {
			const response = await axios.get('https://api.streamefy.com/api/Stats/Overview');
			response.data.dataUploaded = response.data.dataUploaded.split(' ');
			response.data.projectShared = response.data.projectShared.split(' ');
			response.data.filmMakers = response.data.filmMakers.split(' ');
			response.data.clientServed = response.data.clientServed.split(' ');
			setOverviewData(response.data);
		} catch {
			console.log('network error');
		}
	};

	const formik = useFormik({
		initialValues: formData,
		validateOnChange : true,
		validationSchema: Yup.object({
			name: Yup.string()
  				.min(3, "First Name must be at least 3 characters")
  				.max(50, "First Name cannot be more than 50 characters")
				.required('First Name is required'),
			lastname: Yup.string()
  				.min(3, "Last Name must be at least 2 characters")
  				.max(50, "Last Name cannot be more than 50 characters")
				.required('Last Name is required'),
			phoneNumber: Yup.string() 
				.matches(/^\d{10,}$/, 'Invalid phone number or incomplete')
				.required('Phone number is required'),
			email: Yup.string().email('Invalid email address').required('Email is required'),
		}),
		onSubmit: async (values, { resetForm }) => {
			try {
				const response = await axios.post('https://api.streamefy.com/api/ContactUs', values);
				console.log('Response:', response.data);

				// Show success message or any other action on success
				Swal.fire({
					icon: 'success',
					title: 'Success!',
					text: 'Contact information submitted successfully.',
					confirmButtonText: 'OK',
				});
				resetForm();
			} catch (error) {
				console.error('Error submitting the form:', error);
				Swal.fire({
					icon: 'error',
					title: 'Oops...',
					text: 'Something went wrong. Please try again later.',
					confirmButtonText: 'OK',
				});
			}
		},
	});

	const settingsSliderMain = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 5000,
		arrows: false,
	};
	const settingsYouGetAccess = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 2,
		slidesToScroll: 2,
		autoplay: true,
		autoplaySpeed: 5500,
		responsive: [
			{
				breakpoint: 1199, // At or below 1024px
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};
	const settingsShowYourWork = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 4,
		slidesToScroll: 4,
		autoplay: true,
		autoplaySpeed: 6000,
		responsive: [
			{
				breakpoint: 1599, // At or below 1024px
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
				},
			},
			{
				breakpoint: 991, // At or below 1024px
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
				},
			},
			{
				breakpoint: 767, // At or below 1024px
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};
	const settingsKeyFeatures = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 4,
		slidesToScroll: 4,
		autoplay: true,
		autoplaySpeed: 6500,
		responsive: [
			{
				breakpoint: 991,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
				},
			},
		],
	};
	const settingsTestimonial = {
		dots: true,
		infinite: true,
		speed: 500,
		autoplay: true,
		autoplaySpeed: 6000,
		slidesToShow: 2,
		slidesToScroll: 2,
		arrows: false,
		responsive: [
			{
				breakpoint: 1199,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					centerMode: false,
					variableWidth: false,
				},
			},
			{
				breakpoint: 991,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					centerMode: false,
					variableWidth: false,
				},
			},
		],
	};

	useEffect(() => {
		fetchOverviewData();
	}, []);
	return (
		<>
			<section className="main-hero-banner blockElement">
				<div className="slider-main">
					<Slider {...settingsSliderMain}>
						<div className="slide">
							<div className="slider-inner blockElement">
								<img src={Banner1} alt="" />
								<div className="content-over-slide">
									<h6>streamefy Your Masterpiece: </h6>
									<h1>Filmmaker's Ultimate ecosystem</h1>
									<a href="https://app.streamefy.com/?tabId=1" className="cmn-btn">
										<span>
											<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path
													d="M8.2821 16L15.7819 8.52686C15.9216 8.38684 16 8.19743 16 8C16 7.80257 15.9216 7.61316 15.7819 7.47314L8.2821 -3.3736e-07L7.22462 1.05371L13.442 7.24895L-3.16862e-07 7.24895L-3.82194e-07 8.74358L13.442 8.74358L7.22462 14.9388L8.2821 16Z"
													fill="white"
												/>
											</svg>
											start free trial
										</span>
									</a>
								</div>
							</div>
						</div>
						<div className="slide">
							<div className="slider-inner blockElement">
								<img src={banner2} alt="" />
								<div className="content-over-slide">
									<h6>streamefy Your Masterpiece: </h6>
									<h1>download 4K quality videos</h1>
									<a href="https://app.streamefy.com/?tabId=1" className="cmn-btn">
										<span>
											<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path
													d="M8.2821 16L15.7819 8.52686C15.9216 8.38684 16 8.19743 16 8C16 7.80257 15.9216 7.61316 15.7819 7.47314L8.2821 -3.3736e-07L7.22462 1.05371L13.442 7.24895L-3.16862e-07 7.24895L-3.82194e-07 8.74358L13.442 8.74358L7.22462 14.9388L8.2821 16Z"
													fill="white"
												/>
											</svg>
											start free trial
										</span>
									</a>
								</div>
							</div>
						</div>
						<div className="slide">
							<div className="slider-inner blockElement">
								<img src={banner3} alt="" />
								<div className="content-over-slide">
									<h6>streamefy Your Masterpiece: </h6>
									<h1>secure content delivery</h1>
									<a href="https://app.streamefy.com/?tabId=1" className="cmn-btn">
										<span>
											<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path
													d="M8.2821 16L15.7819 8.52686C15.9216 8.38684 16 8.19743 16 8C16 7.80257 15.9216 7.61316 15.7819 7.47314L8.2821 -3.3736e-07L7.22462 1.05371L13.442 7.24895L-3.16862e-07 7.24895L-3.82194e-07 8.74358L13.442 8.74358L7.22462 14.9388L8.2821 16Z"
													fill="white"
												/>
											</svg>
											start free trial
										</span>
									</a>
								</div>
							</div>
						</div>
					</Slider>
					{/* <div className="slide">
						<div className="slider-inner blockElement">
							<img src="assets/images/banner1.jpg" alt="" />
							<div className="content-over-slide">
								<h6>streamefy Your Masterpiece: </h6>
								<h1>Filmmaker's Ultimate ecosystem</h1>
							</div>
						</div>
					</div> */}
					{/* <div className="slide">
						<div className="slider-inner blockElement">
							<img src="assets/images/banner1.jpg" alt="" />
							<div className="content-over-slide">
								<h6>streamefy Your Masterpiece: </h6>
								<h1>Filmmaker's Ultimate ecosystem</h1>
							</div>
						</div>
					</div> */}
					{/* <div className="slide">
						<div className="slider-inner blockElement">
							<img src="assets/images/banner1.jpg" alt="" />
							<div className="content-over-slide">
								<h6>streamefy Your Masterpiece: </h6>
								<h1>Filmmaker's Ultimate ecosystem</h1>
							</div>
						</div>
					</div> */}
				</div>
				<ul className="social-media-icons">
					<li>
						<a href="">
							<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M7.8 2H16.2C19.4032 2 22 4.59675 22 7.8V16.2C22 19.4033 19.4032 22 16.2 22H7.8C4.59675 22 2 19.4033 2 16.2V7.8C2 4.59675 4.59675 2 7.8 2ZM7.6 3.99998C5.61177 3.99998 4 5.61176 4 7.59998V16.4C4 18.3882 5.61177 20 7.6 20H16.4C18.3882 20 20 18.3882 20 16.4V7.59998C20 5.61176 18.3882 3.99998 16.4 3.99998H7.6ZM17.25 5.49998C17.9404 5.49998 18.5 6.05963 18.5 6.74998C18.5 7.44034 17.9404 7.99998 17.25 7.99998C16.5596 7.99998 16 7.44034 16 6.74998C16 6.05963 16.5596 5.49998 17.25 5.49998ZM12 6.99998C14.7614 6.99998 17 9.23856 17 12C17 14.7614 14.7614 17 12 17C9.23857 17 7 14.7614 7 12C7 9.23856 9.23857 6.99998 12 6.99998ZM12 8.99998C10.3431 8.99998 9 10.3432 9 12C9 13.6569 10.3431 15 12 15C13.6569 15 15 13.6569 15 12C15 10.3432 13.6569 8.99998 12 8.99998Z"
									fill="white"
								/>
							</svg>
						</a>
					</li>
					<li>
						<a href="">
							<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M22.4592 6.01238C21.6896 6.35373 20.8624 6.58442 19.9944 6.68815C20.8803 6.15701 21.5609 5.31598 21.8813 4.31378C21.052 4.80564 20.1336 5.16278 19.156 5.3552C18.3732 4.52112 17.2579 4 16.0235 4C13.6534 4 11.7317 5.92147 11.7317 8.29155C11.7317 8.6279 11.7697 8.95546 11.8429 9.2696C8.27609 9.0906 5.11375 7.38203 2.99709 4.78551C2.62765 5.41935 2.41601 6.15656 2.41601 6.94309C2.41601 8.43204 3.17364 9.74563 4.32523 10.5153C3.62179 10.4929 2.95996 10.2999 2.38139 9.97846C2.38098 9.99639 2.38098 10.0143 2.38098 10.0324C2.38098 12.1118 3.86033 13.8463 5.82359 14.2406C5.46349 14.3387 5.08434 14.3912 4.69294 14.3912C4.4164 14.3912 4.14755 14.3642 3.88546 14.3142C4.43161 16.0191 6.01653 17.26 7.89454 17.2945C6.42576 18.4457 4.57527 19.1318 2.56453 19.1318C2.21812 19.1318 1.87651 19.1114 1.54077 19.0717C3.44003 20.2894 5.69591 21 8.1195 21C16.0134 21 20.3302 14.4605 20.3302 8.78918C20.3302 8.60314 20.326 8.41805 20.3177 8.23395C21.1563 7.62886 21.8839 6.87302 22.4592 6.01238Z"
									fill="white"
								/>
							</svg>
						</a>
					</li>
					<li>
						<a href="">
							<svg width="13" height="25" viewBox="0 0 13 25" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M8.66667 14.375H11.7619L13 9.375H8.66667V6.875C8.66667 5.5875 8.66667 4.375 11.1429 4.375H13V0.175C12.5964 0.12125 11.0723 0 9.46276 0C6.10133 0 3.71429 2.07125 3.71429 5.875V9.375H0V14.375H3.71429V25H8.66667V14.375Z"
									fill="white"
								/>
							</svg>
						</a>
					</li>
				</ul>
			</section>

			<section className="page-main-outer blockElement">
				{/* <svg width="4360" height="7206" viewBox="0 0 4360 7206" fill="none" xmlns="http://www.w3.org/2000/svg">
					<g opacity="0.15">
						<path
							d="M2032.63 3482.18C2032.63 3482.18 2031.1 3739.83 2118.5 3935.58C2205.91 4131.32 2309.45 4220.82 2453.66 4214.39"
							stroke="white"
							strokeWidth="0.546667"
							strokeMiterlimit="10"
						/>
						<path
							d="M2025.05 3484.88C2024.87 3491.62 2025.06 3748.57 2112.85 3944.06C2201.29 4140.74 2307.24 4232.18 2453.31 4225.99"
							stroke="white"
							strokeWidth="0.612"
							strokeMiterlimit="10"
						/>
						<path
							d="M2017.46 3487.56C2017.12 3501.05 2019.03 3757.32 2107.2 3952.52C2196.69 4150.14 2305.03 4243.51 2452.94 4237.57"
							stroke="white"
							strokeWidth="0.666667"
							strokeMiterlimit="10"
						/>
						<path
							d="M2009.88 3490.25C2009.35 3510.49 2012.99 3766.06 2101.54 3960.98C2192.07 4159.55 2302.81 4254.84 2452.58 4249.16"
							stroke="white"
							strokeWidth="0.732"
							strokeMiterlimit="10"
						/>
						<path
							d="M2002.29 3492.95C2001.6 3519.92 2006.95 3774.81 2095.89 3969.46C2187.45 4168.95 2300.59 4266.18 2452.21 4260.75"
							stroke="white"
							strokeWidth="0.797333"
							strokeMiterlimit="10"
						/>
						<path
							d="M1994.7 3495.64C1993.84 3529.35 2000.92 3783.55 2090.24 3977.92C2182.84 4178.36 2298.37 4277.53 2451.85 4272.35"
							stroke="white"
							strokeWidth="0.862667"
							strokeMiterlimit="10"
						/>
						<path
							d="M1987.12 3498.33C1986.09 3538.78 1994.87 3792.3 2084.59 3986.4C2178.24 4187.77 2296.16 4288.87 2451.48 4283.94"
							stroke="white"
							strokeWidth="0.917333"
							strokeMiterlimit="10"
						/>
						<path
							d="M1979.53 3501.02C1978.32 3548.23 1988.84 3801.05 2078.93 3994.87C2173.62 4197.19 2293.93 4300.2 2451.12 4295.53"
							stroke="white"
							strokeWidth="0.982667"
							strokeMiterlimit="10"
						/>
						<path
							d="M1971.95 3503.71C1970.57 3557.66 1982.8 3809.79 2073.28 4003.34C2169 4206.59 2291.72 4311.53 2450.75 4307.11"
							stroke="white"
							strokeWidth="1.048"
							strokeMiterlimit="10"
						/>
						<path
							d="M1964.36 3506.4C1962.81 3567.09 1976.77 3818.53 2067.63 4011.81C2164.4 4215.99 2289.51 4322.87 2450.39 4318.71"
							stroke="white"
							strokeWidth="1.10267"
							strokeMiterlimit="10"
						/>
						<path
							d="M1956.77 3509.1C1955.05 3576.53 1970.73 3827.28 2061.97 4020.29C2159.78 4225.4 2287.29 4334.22 2450.04 4330.3"
							stroke="white"
							strokeWidth="1.168"
							strokeMiterlimit="10"
						/>
						<path
							d="M1949.19 3511.78C1947.29 3585.95 1964.69 3836.02 2056.32 4028.75C2155.16 4234.81 2285.06 4345.55 2449.67 4341.88"
							stroke="white"
							strokeWidth="1.23467"
							strokeMiterlimit="10"
						/>
						<path
							d="M1941.6 3514.48C1939.54 3595.4 1958.33 3845 2050.67 4037.21C2150.21 4244.48 2282.85 4356.89 2449.31 4353.47"
							stroke="white"
							strokeWidth="1.3"
							strokeMiterlimit="10"
						/>
						<path
							d="M1934.02 3517.17C1931.78 3604.82 1952.29 3853.76 2045 4045.69C2145.59 4253.88 2280.64 4368.22 2448.95 4365.06"
							stroke="white"
							strokeWidth="1.35467"
							strokeMiterlimit="10"
						/>
						<path
							d="M1926.43 3519.86C1924.02 3614.26 1946.25 3862.51 2039.35 4054.15C2140.97 4263.31 2278.41 4379.56 2448.58 4376.66"
							stroke="white"
							strokeWidth="1.42"
							strokeMiterlimit="10"
						/>
						<path
							d="M1918.84 3522.54C1916.26 3623.7 1940.2 3871.26 2033.7 4062.63C2136.34 4272.72 2276.19 4390.91 2448.22 4388.24"
							stroke="white"
							strokeWidth="1.48533"
							strokeMiterlimit="10"
						/>
						<path
							d="M1911.26 3525.24C1908.51 3633.13 1934.16 3880.02 2028.04 4071.1C2131.72 4282.14 2273.98 4402.25 2447.85 4399.84"
							stroke="white"
							strokeWidth="1.54"
							strokeMiterlimit="10"
						/>
						<path
							d="M1903.67 3527.93C1900.74 3642.57 1928.12 3888.76 2022.39 4079.57C2127.11 4291.54 2271.76 4413.58 2447.49 4411.42"
							stroke="white"
							strokeWidth="1.60533"
							strokeMiterlimit="10"
						/>
						<path
							d="M1896.09 3530.62C1892.98 3651.99 1922.09 3897.5 2016.74 4088.04C2122.51 4300.95 2269.54 4424.91 2447.13 4423.02"
							stroke="white"
							strokeWidth="1.67067"
							strokeMiterlimit="10"
						/>
						<path
							d="M1888.5 3533.31C1885.23 3661.43 1916.06 3906.25 2011.08 4096.5C2117.9 4310.36 2267.33 4436.26 2446.78 4434.61"
							stroke="white"
							strokeWidth="1.736"
							strokeMiterlimit="10"
						/>
						<path
							d="M1880.92 3536C1877.46 3670.85 1910.03 3914.99 2005.43 4104.97C2113.29 4319.76 2265.12 4447.59 2446.41 4446.19"
							stroke="white"
							strokeWidth="1.79067"
							strokeMiterlimit="10"
						/>
						<path
							d="M1873.33 3538.69C1869.71 3680.29 1903.99 3923.72 1999.78 4113.44C2108.68 4329.17 2262.89 4458.93 2446.05 4457.78"
							stroke="white"
							strokeWidth="1.85733"
							strokeMiterlimit="10"
						/>
						<path
							d="M1865.74 3541.38C1861.95 3689.73 1897.97 3932.46 1994.12 4121.91C2104.08 4338.56 2260.67 4470.26 2445.68 4469.37"
							stroke="white"
							strokeWidth="1.92267"
							strokeMiterlimit="10"
						/>
						<path
							d="M1858.16 3544.08C1854.2 3699.17 1892.24 3940.96 1988.47 4130.39C2099.81 4347.7 2258.46 4481.62 2445.32 4480.97"
							stroke="white"
							strokeWidth="1.97733"
							strokeMiterlimit="10"
						/>
						<path
							d="M1850.57 3546.77C1846.43 3708.6 1886.2 3949.72 1982.82 4138.86C2095.2 4357.11 2256.24 4492.95 2444.96 4492.55"
							stroke="white"
							strokeWidth="2.04267"
							strokeMiterlimit="10"
						/>
						<path
							d="M1842.98 3549.46C1838.68 3718.03 1880.16 3958.46 1977.17 4147.33C2090.58 4366.52 2254.02 4504.29 2444.59 4504.14"
							stroke="white"
							strokeWidth="2.108"
							strokeMiterlimit="10"
						/>
						<path
							d="M1835.4 3552.15C1830.92 3727.47 1874.13 3967.2 1971.51 4155.8C2085.97 4375.93 2251.81 4515.62 2444.23 4515.72"
							stroke="white"
							strokeWidth="2.17333"
							strokeMiterlimit="10"
						/>
						<path
							d="M1827.81 3554.84C1823.16 3736.9 1868.08 3975.95 1965.86 4164.27C2081.36 4385.34 2249.59 4526.96 2443.86 4527.33"
							stroke="white"
							strokeWidth="2.228"
							strokeMiterlimit="10"
						/>
						<path
							d="M1820.23 3557.53C1815.4 3746.34 1862.05 3984.7 1960.21 4172.73C2076.75 4394.74 2247.37 4538.31 2443.5 4538.92"
							stroke="white"
							strokeWidth="2.29333"
							strokeMiterlimit="10"
						/>
						<path
							d="M1812.64 3560.23C1807.65 3755.78 1856.02 3993.45 1954.55 4181.21C2072.13 4404.15 2245.15 4549.65 2443.15 4550.51"
							stroke="white"
							strokeWidth="2.35867"
							strokeMiterlimit="10"
						/>
						<path
							d="M1805.05 3562.91C1799.88 3765.2 1849.97 4002.18 1948.89 4189.67C2067.52 4413.56 2242.94 4560.98 2442.78 4562.09"
							stroke="white"
							strokeWidth="2.41333"
							strokeMiterlimit="10"
						/>
						<path
							d="M1797.47 3565.61C1792.13 3774.64 1843.94 4010.93 1943.23 4198.15C2062.91 4422.97 2240.71 4572.31 2442.42 4573.69"
							stroke="white"
							strokeWidth="2.47867"
							strokeMiterlimit="10"
						/>
						<path
							d="M1789.88 3568.29C1784.37 3784.07 1837.9 4019.67 1937.58 4206.61C2058.29 4432.38 2238.5 4583.66 2442.05 4585.28"
							stroke="white"
							strokeWidth="2.54533"
							strokeMiterlimit="10"
						/>
						<path
							d="M1782.29 3570.99C1776.61 3793.51 1831.86 4028.42 1931.93 4215.09C2053.68 4441.77 2236.28 4595 2441.69 4596.86"
							stroke="white"
							strokeWidth="2.61067"
							strokeMiterlimit="10"
						/>
						<path
							d="M1774.71 3573.67C1768.85 3802.95 1825.82 4037.16 1926.28 4223.55C2049.07 4451.18 2234.06 4606.33 2441.33 4608.45"
							stroke="white"
							strokeWidth="2.66533"
							strokeMiterlimit="10"
						/>
						<path d="M1767.12 3576.37C1753.69 4102.2 2061.56 4615.74 2440.96 4620.05" stroke="white" strokeWidth="2.73067" strokeMiterlimit="10" />
						<path
							d="M4359.44 590.875C3811.28 267.202 3063.99 1.17723 2611.73 1.00013C2022.5 0.777859 1797.99 379.616 1797.99 788.295C1797.99 821.39 1799.66 854.115 1802.71 886.56"
							stroke="white"
							strokeWidth="0.546667"
							strokeMiterlimit="10"
						/>
						<path
							d="M4334.78 579.241C3819.65 285.806 3156.9 48.3629 2696.04 21.0101C2666.23 19.205 2637.39 18.3756 2609.6 18.5527C2036.17 21.928 1804.68 394.684 1799.29 791.181C1798.81 823.743 1800.03 855.98 1802.71 887.921"
							stroke="white"
							strokeWidth="0.589333"
							strokeMiterlimit="10"
							strokeLinecap="round"
						/>
						<path
							d="M4310.13 567.603C3793.87 284.233 3157.48 62.1845 2692.52 37.925C2663.25 36.3404 2634.87 35.735 2607.48 36.1037C2049.83 43.0746 1811.36 409.747 1800.59 794.047C1799.62 826.093 1800.39 857.827 1802.71 889.264"
							stroke="white"
							strokeWidth="0.644"
							strokeMiterlimit="10"
							strokeLinecap="round"
						/>
						<path
							d="M4285.47 555.963C3768.08 282.671 3158.05 76.0018 2689 54.8502C2660.26 53.4896 2632.35 53.0741 2605.35 53.6378C2063.49 64.2208 1818.05 424.793 1801.91 796.928C1800.44 828.44 1800.76 859.67 1802.71 890.621"
							stroke="white"
							strokeWidth="0.688"
							strokeMiterlimit="10"
							strokeLinecap="round"
						/>
						<path
							d="M4260.81 544.33C3742.3 281.105 3158.63 89.828 2685.48 71.7698C2657.28 70.6297 2629.84 70.4381 2603.23 71.1916C2077.15 85.3577 1825.33 439.922 1803.21 799.814C1801.3 830.794 1801.12 861.536 1802.71 891.968"
							stroke="white"
							strokeWidth="0.732"
							strokeMiterlimit="10"
							strokeLinecap="round"
						/>
						<path
							d="M4236.16 532.691C3716.52 279.544 3159.21 103.646 2681.96 88.6816C2654.29 87.7637 2627.32 87.7944 2601.1 88.7412C2090.82 106.503 1831.42 454.909 1804.51 802.696C1802.07 833.128 1801.49 863.381 1802.71 893.308"
							stroke="white"
							strokeWidth="0.776"
							strokeMiterlimit="10"
							strokeLinecap="round"
						/>
						<path
							d="M4211.5 521.057C3690.74 277.975 3159.79 117.47 2678.43 105.615C2651.3 104.92 2624.81 105.142 2598.98 106.282C2104.48 127.654 1838.11 469.978 1805.82 805.583C1802.89 835.481 1801.86 865.246 1802.71 894.672"
							stroke="white"
							strokeWidth="0.830667"
							strokeMiterlimit="10"
							strokeLinecap="round"
						/>
						<path
							d="M4186.84 509.42C3664.95 276.418 3160.37 131.292 2674.92 122.53C2102.41 112.198 1796.55 531.755 1802.71 896.015"
							stroke="white"
							strokeWidth="0.873333"
							strokeMiterlimit="10"
							strokeLinecap="round"
						/>
						<path
							d="M2.00683 2342.85C2.00682 2523.14 50.154 2571.22 117.558 2541.16M117.558 2541.16L117.57 2541.16M117.558 2541.16C416.092 2408.95 1216.75 2341 2141.22 2485.22C2273.14 2506.03 3032.6 2625.63 3137.87 2643.33C3441.54 2694.39 3734.71 2278.49 3516.08 2127"
							stroke="white"
							strokeWidth="2.73067"
							strokeMiterlimit="10"
						/>
						<path
							d="M3491.85 2121.49C3704.44 2268.51 3412.87 2671.35 3109.14 2620.02C3000.44 2601.57 2254.59 2483.91 2119.73 2462.64C1845.2 2419.81 1582.32 2395.62 1343.3 2386.84C771.578 2366.7 320.721 2431.5 114.994 2529.59C49.0114 2560.68 2.0034 2515.98 2.00341 2342.85"
							stroke="white"
							strokeWidth="2.66533"
							strokeMiterlimit="10"
						/>
						<path
							d="M3467.63 2115.96C3674.18 2258.51 3384.21 2648.32 3080.41 2596.72C2968.26 2577.48 2236.06 2461.8 2098.24 2440.07C1824.14 2397.29 1562.41 2373.02 1327.47 2364.73C758.274 2346.29 314.151 2414.71 112.422 2518.01C47.8737 2550.16 2.0034 2508.83 2.00341 2342.85"
							stroke="white"
							strokeWidth="2.61067"
							strokeMiterlimit="10"
						/>
						<path
							d="M3443.4 2110.45C3643.92 2248.53 3355.54 2625.28 3051.68 2573.43C2936.08 2553.42 2217.51 2439.67 2076.75 2417.5C1803.08 2374.78 1542.49 2350.43 1311.64 2342.62C744.97 2325.88 307.572 2397.95 109.849 2506.44C46.7373 2539.65 2.00535 2501.67 2.00536 2342.86"
							stroke="white"
							strokeWidth="2.54533"
							strokeMiterlimit="10"
						/>
						<path
							d="M3419.17 2104.92C3613.67 2238.53 3326.88 2602.24 3022.95 2550.13C2903.91 2529.34 2198.96 2417.55 2055.25 2394.91C1782.02 2352.25 1522.59 2327.19 1295.81 2320.5C731.689 2303.83 301.002 2381.15 107.277 2494.86C45.5878 2529.12 2.00682 2494.52 2.00683 2342.85"
							stroke="white"
							strokeWidth="2.49067"
							strokeMiterlimit="10"
						/>
						<path
							d="M3394.95 2099.4C3583.41 2228.54 3298.21 2579.22 2994.23 2526.82C2871.74 2505.28 2180.42 2395.44 2033.76 2372.34C1760.96 2329.74 1502.67 2304.47 1279.98 2298.39C718.384 2283.06 294.421 2364.38 104.704 2483.29C44.4501 2518.6 2.00682 2487.36 2.00683 2342.85"
							stroke="white"
							strokeWidth="2.42533"
							strokeMiterlimit="10"
						/>
						<path
							d="M3370.72 2093.88C3553.14 2218.55 3269.55 2556.17 2965.49 2503.52C2839.57 2481.2 2161.88 2373.31 2012.26 2349.75C1739.9 2307.23 1482.76 2281.75 1264.16 2276.28C705.081 2262.27 287.853 2347.6 102.132 2471.71C43.3121 2508.08 2.00828 2480.21 2.00829 2342.85"
							stroke="white"
							strokeWidth="2.37067"
							strokeMiterlimit="10"
						/>
						<path
							d="M3346.5 2088.36C3522.88 2208.56 3240.89 2533.13 2936.76 2480.22C2807.4 2457.13 2143.34 2351.2 1990.78 2327.18C1718.84 2284.7 1462.84 2259.03 1248.33 2254.16C691.778 2241.51 281.274 2330.82 99.5618 2460.14M99.5618 2460.14L99.5749 2460.14M99.5618 2460.14C42.1784 2497.55 2.01219 2473.04 2.0122 2342.85"
							stroke="white"
							strokeWidth="2.304"
							strokeMiterlimit="10"
						/>
						<path
							d="M3322.27 2082.83C3492.62 2198.56 3212.22 2510.1 2908.04 2456.92C2775.21 2433.06 2124.79 2329.08 1969.28 2304.59C1697.78 2262.18 1442.91 2236.34 1232.5 2232.05C678.473 2220.75 274.693 2314.04 96.989 2448.56M96.989 2448.56L97.0021 2448.56M96.989 2448.56C41.0272 2487.03 2.00047 2465.88 2.00048 2342.85"
							stroke="white"
							strokeWidth="2.24933"
							strokeMiterlimit="10"
						/>
						<path
							d="M3298.04 2077.32C3462.36 2188.58 3183.56 2487.06 2879.31 2433.62C2743.04 2409 2106.25 2306.98 1947.79 2282.02C1676.74 2239.66 1423.01 2213.64 1216.67 2209.94C665.158 2200.02 268.125 2297.27 94.4184 2436.99L94.43 2436.99L94.4184 2437.01C39.8912 2476.51 2.00242 2458.74 2.00243 2342.86"
							stroke="white"
							strokeWidth="2.184"
							strokeMiterlimit="10"
						/>
						<path
							d="M3273.82 2071.8C3432.1 2178.59 3154.8 2464.82 2850.58 2410.32C2710.83 2385.29 2087.7 2284.85 1926.29 2259.45C1655.68 2217.15 1403.08 2190.96 1200.84 2187.82C651.853 2179.3 261.545 2280.49 91.8586 2425.41L91.8456 2425.43C38.7545 2465.98 2.0034 2451.57 2.00341 2342.85"
							stroke="white"
							strokeWidth="2.11867"
							strokeMiterlimit="10"
						/>
						<path
							d="M3249.59 2066.27C3401.83 2168.6 3126.14 2441.81 2821.86 2387.02C2678.65 2361.23 2069.17 2262.73 1904.81 2236.86C1634.61 2194.63 1383.16 2168.28 1185.01 2165.71C638.536 2158.59 254.977 2263.71 89.2865 2413.84L89.2734 2413.85C37.617 2455.46 2.00535 2444.42 2.00536 2342.85"
							stroke="white"
							strokeWidth="2.064"
							strokeMiterlimit="10"
						/>
						<path
							d="M3225.37 2060.75C3371.57 2158.6 3097.47 2418.8 2793.13 2363.72C2646.48 2337.18 2050.62 2240.62 1883.31 2214.29C1613.56 2172.1 1363.24 2145.62 1169.18 2143.6C625.22 2137.91 248.396 2246.94 86.7139 2402.26L86.7139 2402.28C36.469 2444.94 2.00682 2437.25 2.00682 2342.85"
							stroke="white"
							strokeWidth="1.99867"
							strokeMiterlimit="10"
						/>
						<path
							d="M3201.14 2055.24C3341.32 2148.62 3068.81 2395.77 2764.4 2340.41C2614.3 2313.13 2032.08 2218.49 1861.82 2191.7C1592.5 2149.59 1343.33 2122.98 1153.35 2121.49C611.916 2117.24 241.816 2230.16 84.1415 2390.69L84.1415 2390.71C35.3299 2434.42 2.00682 2430.11 2.00682 2342.85"
							stroke="white"
							strokeWidth="1.944"
							strokeMiterlimit="10"
						/>
						<path
							d="M3176.91 2049.71C3311.06 2138.62 3040.14 2372.73 2735.67 2317.11C2582.12 2289.06 2013.53 2196.37 1840.32 2169.12C1571.44 2127.07 1323.41 2100.33 1137.52 2099.37C598.599 2096.58 235.247 2213.36 81.5689 2379.13M81.5689 2379.13L81.5689 2379.11M81.5689 2379.13C34.1933 2423.9 2.00828 2422.94 2.00829 2342.85"
							stroke="white"
							strokeWidth="1.87867"
							strokeMiterlimit="10"
						/>
						<path
							d="M3152.68 2044.19C3280.79 2128.63 3011.48 2349.71 2706.94 2293.82C2549.95 2265 1994.98 2174.27 1818.83 2146.54C1550.37 2104.55 1303.49 2077.7 1121.7 2077.26C585.282 2075.96 228.665 2196.6 78.9948 2367.56M78.9948 2367.56L78.9948 2367.54M78.9948 2367.56C33.0539 2413.38 2.00828 2415.79 2.00829 2342.85"
							stroke="white"
							strokeWidth="1.824"
							strokeMiterlimit="10"
						/>
						<path
							d="M3128.46 2038.67C3250.53 2118.64 2982.81 2326.66 2678.22 2270.52C2517.78 2240.94 1976.45 2152.15 1797.34 2123.97C1529.32 2082.04 1283.57 2055.07 1105.88 2055.14C571.97 2055.35 222.1 2179.81 76.426 2355.98M76.426 2355.98L76.426 2355.97M76.426 2355.98C31.9082 2402.85 2.01219 2408.63 2.01219 2342.85"
							stroke="white"
							strokeWidth="1.75867"
							strokeMiterlimit="10"
						/>
						<path
							d="M3104.23 2033.15C3220.27 2108.65 2954.15 2303.61 2649.49 2247.21C2485.6 2216.88 1957.91 2130.02 1775.85 2101.4C1508.27 2059.52 1263.64 2032.47 1090.04 2033.03C558.641 2034.78 215.518 2163.05 73.8533 2344.41C30.7701 2392.33 2.00047 2401.48 2.00047 2342.85"
							stroke="white"
							strokeWidth="1.704"
							strokeMiterlimit="10"
						/>
						<path
							d="M3080.01 2027.63C3190.01 2098.66 2925.49 2280.57 2620.75 2223.91C2453.43 2192.81 1939.36 2107.91 1754.35 2078.81C1487.21 2037 1243.72 2009.86 1074.21 2010.92C545.325 2014.22 208.939 2146.26 71.2825 2332.83C29.6339 2381.81 2.0034 2394.31 2.0034 2342.85"
							stroke="white"
							strokeWidth="1.63867"
							strokeMiterlimit="10"
						/>
						<path
							d="M3055.78 2022.1C3159.75 2088.67 2896.83 2257.5 2592.03 2200.61C2421.26 2168.73 1920.81 2085.79 1732.86 2056.24C1466.15 2014.48 1223.8 1987.26 1058.39 1988.8C532.009 1993.69 202.37 2129.49 68.7094 2321.25C28.4969 2371.28 2.00438 2387.16 2.00438 2342.85"
							stroke="white"
							strokeWidth="1.57333"
							strokeMiterlimit="10"
						/>
						<path
							d="M3031.55 2016.58C3129.49 2078.67 2868.16 2234.44 2563.3 2177.31C2389.08 2144.66 1902.28 2063.67 1711.36 2033.65C1445.09 1991.97 1203.88 1964.69 1042.55 1966.69C518.691 1973.19 195.789 2112.7 66.137 2309.68C27.3477 2360.76 2.00438 2380 2.00438 2342.85"
							stroke="white"
							strokeWidth="1.51867"
							strokeMiterlimit="10"
						/>
						<path
							d="M3007.33 2011.06C3099.22 2068.68 2839.6 2210.64 2534.57 2154.01C2356.96 2120.13 1883.73 2041.55 1689.88 2011.08C1424.03 1969.44 1183.96 1942.12 1026.72 1944.57C505.364 1952.71 189.22 2095.93 63.5653 2298.1C26.2092 2350.23 2.00535 2372.85 2.00535 2342.85"
							stroke="white"
							strokeWidth="1.452"
							strokeMiterlimit="10"
						/>
						<path
							d="M2983.1 2005.54C3068.96 2058.7 2810.92 2187.6 2505.85 2130.7C2324.78 2096.07 1865.19 2019.44 1668.38 1988.49C1402.97 1946.93 1164.04 1919.56 1010.89 1922.46C492.046 1932.29 182.639 2079.15 60.9908 2286.53C25.0707 2339.71 2.00633 2365.69 2.00633 2342.85"
							stroke="white"
							strokeWidth="1.39867"
							strokeMiterlimit="10"
						/>
						<path
							d="M2958.88 2000.02C3038.71 2048.7 2782.26 2164.56 2477.11 2107.4C2292.62 2072 1846.64 1997.33 1646.89 1965.92C1381.91 1924.42 1144.12 1897.02 995.063 1900.35C478.732 1911.89 176.06 2062.36 58.4204 2274.95C23.935 2329.18 2.00828 2358.52 2.00828 2342.85"
							stroke="white"
							strokeWidth="1.332"
							strokeMiterlimit="10"
						/>
						<path
							d="M2934.65 1994.5C3008.45 2038.72 2753.6 2141.54 2448.38 2084.11C2260.45 2047.94 1828.1 1975.2 1625.4 1943.35C1360.86 1901.89 1124.2 1874.49 979.246 1878.24C465.417 1891.55 169.494 2045.6 55.8502 2263.38C22.7879 2318.68 2.01219 2351.38 2.01219 2342.85"
							stroke="white"
							strokeWidth="1.27733"
							strokeMiterlimit="10"
						/>
						<path
							d="M2910.43 1988.98C2978.19 2028.72 2724.94 2118.49 2419.66 2060.81C2228.26 2023.85 1809.56 1953.08 1603.91 1920.76C1339.8 1879.37 1104.28 1851.96 963.415 1856.12C452.101 1871.23 162.912 2028.81 53.2901 2251.8L53.2787 2251.8C21.651 2308.15 2.01316 2344.21 2.01316 2342.84"
							stroke="white"
							strokeWidth="1.212"
							strokeMiterlimit="10"
						/>
						<path
							d="M2886.2 1983.46C2947.92 2018.75 2696.28 2095.46 2390.93 2037.5C2196.09 1999.79 1791.02 1930.98 1582.41 1898.19C1318.74 1856.87 1084.36 1829.45 947.586 1834.01C438.784 1850.97 156.345 2012.04 50.7186 2240.23C50.7186 2240.23 50.7071 2240.23 50.7071 2240.25C20.5141 2297.63 2.00242 2337.06 2.00242 2342.85"
							stroke="white"
							strokeWidth="1.15733"
							strokeMiterlimit="10"
						/>
						<path
							d="M2861.97 1977.94C2917.66 2008.75 2667.61 2072.43 2362.2 2014.2C2163.92 1975.72 1772.47 1908.84 1560.92 1875.6C1297.68 1834.34 1064.44 1806.95 931.755 1811.9C425.468 1830.77 149.763 1995.25 48.1445 2228.65L48.133 2228.67C19.3645 2287.11 2.00242 2329.9 2.00242 2342.85"
							stroke="white"
							strokeWidth="1.092"
							strokeMiterlimit="10"
						/>
						<path
							d="M2837.75 1972.42C2887.4 1998.77 2638.94 2049.39 2333.47 1990.91C2131.74 1951.64 1753.92 1886.74 1539.42 1853.03C1276.62 1811.83 1044.52 1784.47 915.923 1789.79C412.151 1810.61 143.182 1978.49 45.5719 2217.09L45.5719 2217.1C18.2251 2276.59 2.00242 2322.75 2.00242 2342.85"
							stroke="white"
							strokeWidth="1.02667"
							strokeMiterlimit="10"
						/>
						<path
							d="M2813.52 1966.9C2857.14 1988.77 2610.28 2026.35 2304.74 1967.61C2099.57 1927.57 1735.39 1864.62 1517.94 1830.46C1255.56 1789.3 1024.6 1762 900.094 1767.67C398.847 1790.52 136.614 1961.7 43.001 2205.51L43.001 2205.52C17.0888 2266.06 2.00535 2315.58 2.00535 2342.85"
							stroke="white"
							strokeWidth="0.972"
							strokeMiterlimit="10"
						/>
						<path
							d="M2789.3 1961.38C2826.88 1978.78 2581.62 2003.32 2276.01 1944.29C2067.4 1903.5 1716.85 1842.51 1496.44 1807.87C1234.5 1766.78 1004.69 1739.53 884.265 1745.56C385.544 1770.51 130.034 1944.93 40.4294 2193.93L40.4294 2193.95C15.9519 2255.53 2.00632 2308.44 2.00632 2342.85"
							stroke="white"
							strokeWidth="0.906667"
							strokeMiterlimit="10"
						/>
						<path
							d="M2765.07 1955.85C2796.61 1968.79 2552.96 1980.27 2247.29 1921C2035.22 1879.43 1698.3 1820.38 1474.95 1785.3C1213.44 1744.27 984.768 1717.09 868.433 1723.44C372.238 1750.56 123.465 1928.14 37.8555 2182.36L37.8555 2182.37C14.8025 2245.01 2.00632 2301.27 2.00632 2342.85"
							stroke="white"
							strokeWidth="0.852"
							strokeMiterlimit="10"
						/>
						<path
							d="M2740.84 1950.33C2766.36 1958.8 2524.29 1957.24 2218.55 1897.7C2003.05 1855.35 1679.75 1798.26 1453.45 1762.71C1192.39 1721.74 964.858 1694.65 852.603 1701.33C358.946 1730.71 116.884 1911.36 35.2833 2170.78L35.2833 2170.8C13.665 2234.49 2.00827 2294.12 2.00827 2342.85"
							stroke="white"
							strokeWidth="0.786667"
							strokeMiterlimit="10"
						/>
						<path
							d="M2716.62 1944.81C2736.1 1948.81 2495.63 1934.21 2189.82 1874.4C1970.88 1831.29 1661.21 1776.15 1431.96 1740.14C1171.33 1699.23 944.937 1672.23 836.786 1679.22C345.654 1710.95 110.305 1894.59 32.7128 2159.21L32.7128 2159.22C12.5291 2223.96 2.01023 2286.96 2.01022 2342.85"
							stroke="white"
							strokeWidth="0.732"
							strokeMiterlimit="10"
						/>
						<path
							d="M2692.39 1939.3C2705.84 1938.82 2466.97 1911.17 2161.1 1851.1C1938.71 1807.22 1642.67 1754.03 1410.47 1717.56C1150.27 1676.72 925.029 1649.83 820.956 1657.11C332.374 1691.3 103.737 1877.81 30.1407 2147.64L30.1407 2147.65C11.3931 2213.44 2.01218 2279.81 2.01218 2342.85"
							stroke="white"
							strokeWidth="0.666667"
							strokeMiterlimit="10"
						/>
						<path
							d="M2668.16 1933.77C2675.57 1928.83 2438.3 1888.14 2132.37 1827.79C1906.53 1783.15 1624.13 1731.91 1388.98 1694.98C1129.21 1654.19 905.13 1627.63 805.125 1634.99C319.153 1672.77 97.1555 1861.04 27.5681 2136.06L27.5681 2136.07C10.2436 2202.93 2.00046 2272.65 2.00046 2342.85"
							stroke="white"
							strokeWidth="0.612"
							strokeMiterlimit="10"
						/>
						<path
							d="M2643.94 1928.25C2647.47 1904.09 1090.8 1588.26 789.294 1612.87C188.706 1661.91 2.00243 1946.2 2.00241 2342.84"
							stroke="white"
							strokeWidth="0.546667"
							strokeMiterlimit="10"
						/>
						<path
							d="M3516.08 2127.01C2230.36 1322.08 2085.3 1107.97 2264.38 887.252C2482.7 618.179 3754.58 1208.54 4288.51 1303.15"
							stroke="white"
							strokeWidth="2.73067"
							strokeMiterlimit="10"
						/>
						<path
							d="M4290.49 1283.37C4290.43 1283.36 4290.37 1283.36 4290.31 1283.34C3914.49 1211.54 3195.21 912.068 2711.36 823.571C2648.63 812.085 2589.88 804.27 2536.42 800.985C2407.66 792.814 2309.84 811.36 2261.08 870.032C2147.37 1006.88 2158.26 1141.25 2480.22 1417.24C2677.28 1586.15 2993.48 1810.12 3477.11 2112.23C3482.03 2115.31 3486.95 2118.39 3491.91 2121.48"
							stroke="white"
							strokeWidth="2.66533"
							strokeMiterlimit="10"
						/>
						<path
							d="M4292.47 1263.59C4292.41 1263.57 4292.35 1263.56 4292.29 1263.56C3910.21 1185.5 3194.4 889.353 2709.18 803.551C2646.36 792.406 2587.46 785.034 2533.77 782.193C2405.56 774.866 2307.66 794.151 2257.8 852.809C2141.38 989.676 2149.61 1124.28 2460.86 1402.02C2653.13 1573.57 2966.07 1803.6 3452.89 2106.7C3457.82 2109.76 3462.77 2112.86 3467.74 2115.95"
							stroke="white"
							strokeWidth="2.61067"
							strokeMiterlimit="10"
						/>
						<path
							d="M4294.44 1243.81C4294.38 1243.79 4294.32 1243.78 4294.26 1243.76C3905.91 1159.44 3193.58 866.656 2707 783.519C2644.08 772.728 2585.03 765.963 2531.11 763.403C2403.44 757.335 2305.47 776.947 2254.5 835.59C2135.38 972.486 2140.96 1107.46 2441.51 1386.8C2629.02 1561.09 2938.67 1797.1 3428.67 2101.16C3433.62 2104.24 3438.57 2107.33 3443.56 2110.44"
							stroke="white"
							strokeWidth="2.54533"
							strokeMiterlimit="10"
						/>
						<path
							d="M4296.42 1224.02C4296.36 1224.01 4296.3 1224 4296.24 1223.98C3901.63 1133.4 3192.76 843.958 2704.83 763.482C2641.81 753.033 2582.6 746.772 2528.46 744.596C2401.33 739.505 2303.3 759.753 2251.21 818.367C2129.39 955.277 2132.32 1090.8 2422.15 1371.58C2604.94 1548.66 2911.27 1790.57 3404.45 2095.63C3409.42 2098.71 3414.39 2101.8 3419.39 2104.91"
							stroke="white"
							strokeWidth="2.49067"
							strokeMiterlimit="10"
						/>
						<path
							d="M4298.39 1204.25C4298.33 1204.23 4298.28 1204.22 4298.22 1204.2C3897.33 1107.34 3191.93 821.248 2702.64 743.452C2639.54 733.357 2580.18 727.586 2525.82 725.81C2399.22 721.665 2301.13 742.549 2247.92 801.163C2123.4 938.09 2123.67 1074.3 2402.79 1356.37C2580.9 1536.34 2883.85 1784.06 3380.22 2090.09C3385.22 2093.17 3390.19 2096.29 3395.22 2099.38"
							stroke="white"
							strokeWidth="2.42533"
							strokeMiterlimit="10"
						/>
						<path
							d="M4300.37 1184.46C4300.31 1184.45 4300.25 1184.43 4300.19 1184.42C3893.05 1081.3 3191.11 798.549 2700.47 723.433C2637.26 713.679 2577.75 708.394 2523.17 707.019C2397.12 703.821 2298.95 725.357 2244.62 783.94C2117.41 920.882 2115.02 1058 2383.43 1341.15C2556.88 1524.11 2856.45 1777.55 3356 2084.56C3361.01 2087.64 3365.99 2090.76 3371.05 2093.87"
							stroke="white"
							strokeWidth="2.37067"
							strokeMiterlimit="10"
						/>
						<path
							d="M4302.35 1164.68C4302.28 1164.67 4302.23 1164.65 4302.17 1164.63C3888.75 1055.25 3190.29 775.835 2698.28 703.398C2634.99 693.984 2575.33 689.203 2520.52 688.211C2395.01 685.976 2296.78 708.148 2241.33 766.717C2111.41 903.689 2106.34 1041.86 2364.08 1325.93C2532.89 1511.98 2829.03 1771.03 3331.78 2079.01C3336.8 2082.11 3341.81 2085.23 3346.87 2088.34"
							stroke="white"
							strokeWidth="2.304"
							strokeMiterlimit="10"
						/>
						<path
							d="M4304.32 1144.9C4304.25 1144.88 4304.2 1144.87 4304.13 1144.85C3884.46 1029.19 3189.47 753.136 2696.11 683.363C2632.72 674.303 2572.9 669.997 2517.87 669.419C2392.91 668.102 2294.59 690.941 2238.04 749.493C2105.42 886.48 2097.63 1025.92 2344.72 1310.71C2508.9 1499.95 2801.64 1764.52 3307.56 2073.48C3312.61 2076.58 3317.61 2079.7 3322.7 2082.81"
							stroke="white"
							strokeWidth="2.24933"
							strokeMiterlimit="10"
						/>
						<path
							d="M4306.3 1125.12C4306.23 1125.1 4306.17 1125.09 4306.11 1125.07C3880.17 1003.15 3188.65 730.44 2693.93 663.344C2630.44 654.628 2570.48 650.793 2515.22 650.631C2390.81 650.246 2292.42 673.75 2234.74 732.275C2099.43 869.274 2088.88 1010.18 2325.36 1295.49C2484.94 1488.03 2774.23 1758 3283.33 2067.94C3288.4 2071.04 3293.43 2074.17 3298.53 2077.3"
							stroke="white"
							strokeWidth="2.184"
							strokeMiterlimit="10"
						/>
						<path
							d="M4308.28 1105.33C4308.21 1105.31 4308.15 1105.31 4308.09 1105.27C3875.88 977.111 3187.82 707.728 2691.75 643.312C2628.17 634.935 2568.06 631.59 2512.57 631.827C2388.71 632.36 2290.25 656.545 2231.45 715.055C2093.44 852.084 2080.1 994.651 2306.01 1280.29C2460.99 1476.22 2746.82 1751.5 3259.11 2062.41C3264.2 2065.51 3269.24 2068.65 3274.36 2071.77"
							stroke="white"
							strokeWidth="2.11867"
							strokeMiterlimit="10"
						/>
						<path
							d="M4310.25 1085.55C4310.18 1085.52 4310.12 1085.52 4310.06 1085.49C3871.58 951.052 3187 685.029 2689.57 623.278C2625.9 615.256 2565.64 612.385 2509.92 613.035C2386.6 614.472 2288.07 639.353 2228.15 697.833C2087.43 834.877 2071.23 979.323 2286.65 1265.06C2437.03 1464.54 2719.41 1744.98 3234.89 2056.87C3240 2059.98 3245.05 2063.12 3250.18 2066.24"
							stroke="white"
							strokeWidth="2.064"
							strokeMiterlimit="10"
						/>
						<path
							d="M4312.23 1065.77C4312.16 1065.74 4312.1 1065.74 4312.04 1065.71C3867.3 925.006 3186.18 662.314 2687.4 603.243C2623.63 595.574 2563.21 593.162 2507.26 594.243C2384.51 596.583 2285.88 622.143 2224.86 680.623C2081.44 817.684 2062.31 964.202 2267.29 1249.84C2413.06 1452.97 2692 1738.47 3210.66 2051.34C3215.79 2054.43 3220.85 2057.59 3226.01 2060.72"
							stroke="white"
							strokeWidth="1.99867"
							strokeMiterlimit="10"
						/>
						<path
							d="M4314.21 1045.98C4314.14 1045.95 4314.08 1045.94 4314.02 1045.92C3863 898.959 3185.37 639.611 2685.21 583.219C2621.35 575.878 2560.79 573.953 2504.62 575.433C2382.41 578.675 2283.71 604.947 2221.56 663.397C2075.45 800.472 2053.29 949.284 2247.93 1234.62C2389.06 1441.51 2664.59 1731.95 3186.44 2045.79C3191.59 2048.9 3196.66 2052.05 3201.85 2055.19"
							stroke="white"
							strokeWidth="1.944"
							strokeMiterlimit="10"
						/>
						<path
							d="M4316.18 1026.2C4316.11 1026.17 4316.05 1026.16 4315.99 1026.14C3858.72 872.906 3184.54 616.903 2683.04 563.189C2619.08 556.204 2558.37 554.739 2501.97 556.648C2380.31 560.763 2281.54 587.745 2218.27 646.18C2069.45 783.286 2044.18 934.583 2228.57 1219.4C2365.04 1430.2 2637.19 1725.43 3162.22 2040.27C3167.39 2043.38 3172.47 2046.53 3177.68 2049.67"
							stroke="white"
							strokeWidth="1.87867"
							strokeMiterlimit="10"
						/>
						<path
							d="M4318.16 1006.42C4318.09 1006.39 4318.03 1006.37 4317.97 1006.36C3854.42 846.86 3183.72 594.203 2680.85 543.153C2616.81 536.521 2555.94 535.515 2499.32 537.855C2378.2 542.842 2279.36 570.534 2214.98 628.955C2063.46 766.075 2034.96 920.066 2209.22 1204.18C2340.97 1419 2609.78 1718.92 3138 2034.72C3143.18 2037.83 3148.28 2041 3153.51 2044.15"
							stroke="white"
							strokeWidth="1.824"
							strokeMiterlimit="10"
						/>
						<path
							d="M4320.13 986.633C4320.06 986.602 4320 986.587 4319.93 986.559C3850.14 820.813 3182.9 571.501 2678.68 523.116C2614.53 516.824 2553.53 516.277 2496.67 519.045C2376.1 524.907 2277.19 553.34 2211.68 611.73C2057.47 748.879 2025.64 905.758 2189.86 1188.97C2316.84 1407.94 2582.37 1712.4 3113.76 2029.19C3118.98 2032.3 3124.08 2035.48 3129.33 2038.62"
							stroke="white"
							strokeWidth="1.75867"
							strokeMiterlimit="10"
						/>
						<path
							d="M4322.11 966.857C4322.03 966.826 4321.98 966.812 4321.91 966.783C3845.84 794.762 3182.08 548.795 2676.51 503.104C2612.27 497.153 2551.11 497.065 2494.02 500.261C2374.01 506.981 2275 536.139 2208.39 594.516C2051.47 731.68 2016.17 891.652 2170.5 1173.75C2292.65 1397.01 2554.96 1705.9 3089.55 2023.65C3094.78 2026.78 3099.9 2029.96 3105.16 2033.1"
							stroke="white"
							strokeWidth="1.704"
							strokeMiterlimit="10"
						/>
						<path
							d="M4324.09 947.067C4324 947.038 4323.96 947.022 4323.89 946.993C3841.56 768.712 3181.26 526.088 2674.32 483.062C2610 477.466 2548.69 477.822 2491.37 481.463C2371.91 489.041 2272.83 518.94 2205.09 577.287C2045.48 714.479 2006.56 877.693 2151.14 1158.53C2268.36 1386.19 2527.55 1699.37 3065.33 2018.12C3070.57 2021.23 3075.7 2024.42 3080.99 2027.57"
							stroke="white"
							strokeWidth="1.63867"
							strokeMiterlimit="10"
						/>
						<path
							d="M4326.06 927.291C4325.98 927.262 4325.92 927.245 4325.86 927.216C3837.26 742.66 3180.43 503.383 2672.14 463.033C2607.73 457.779 2546.26 458.593 2488.72 462.664C2369.8 471.086 2270.65 501.739 2201.8 560.085C2039.49 697.279 1996.81 863.926 2131.79 1143.31C2243.97 1375.5 2500.14 1692.87 3041.11 2012.58C3046.37 2015.7 3051.52 2018.9 3056.82 2022.05"
							stroke="white"
							strokeWidth="1.584"
							strokeMiterlimit="10"
						/>
						<path
							d="M4328.04 907.504C4327.96 907.475 4327.9 907.459 4327.84 907.43C3832.97 716.613 3179.61 480.68 2669.96 443.01C2605.45 438.097 2543.84 439.34 2486.08 443.868C2367.7 453.134 2268.48 484.529 2198.51 542.859C2033.5 680.082 1986.89 850.298 2112.43 1128.09C2219.47 1364.92 2472.75 1686.35 3016.87 2007.05C3022.16 2010.17 3027.32 2013.37 3032.65 2016.52"
							stroke="white"
							strokeWidth="1.51867"
							strokeMiterlimit="10"
						/>
						<path
							d="M4330.02 887.727C4329.94 887.682 4329.88 887.682 4329.82 887.653C3828.68 690.574 3178.79 457.972 2667.78 422.981C2603.18 418.423 2541.42 420.11 2483.42 425.083C2365.6 435.163 2266.31 467.341 2195.21 525.642C2027.51 662.88 1976.81 836.81 2093.07 1112.88C2194.82 1354.46 2445.34 1679.83 2992.66 2001.51C2997.97 2004.63 3003.13 2007.84 3008.47 2011.01"
							stroke="white"
							strokeWidth="1.452"
							strokeMiterlimit="10"
						/>
						<path
							d="M4332 867.939C4331.91 867.894 4331.85 867.894 4331.79 867.865C3824.39 664.511 3177.97 435.268 2665.61 402.943C2600.91 398.723 2538.99 400.856 2480.77 406.273C2363.5 417.195 2264.12 450.128 2191.92 508.417C2021.5 645.669 1966.55 823.417 2073.72 1097.65C2170.02 1344.08 2417.93 1673.32 2968.44 1995.97C2973.76 1999.1 2978.94 2002.31 2984.3 2005.48"
							stroke="white"
							strokeWidth="1.39867"
							strokeMiterlimit="10"
						/>
						<path
							d="M4333.97 848.158C4333.89 848.113 4333.83 848.113 4333.77 848.07C3820.09 638.468 3177.16 412.571 2663.42 382.909C2598.63 379.048 2536.57 381.608 2478.12 387.484C2361.41 399.22 2261.94 432.938 2188.62 491.196C2015.51 628.477 1956.12 810.134 2054.36 1082.43C2145.05 1333.8 2390.52 1666.8 2944.22 1990.43C2949.55 1993.57 2954.75 1996.78 2960.13 1999.95"
							stroke="white"
							strokeWidth="1.332"
							strokeMiterlimit="10"
						/>
						<path
							d="M4335.95 828.372C4335.86 828.327 4335.8 828.313 4335.73 828.284C3815.81 612.421 3176.34 389.854 2661.25 362.886C2596.36 359.365 2534.15 362.355 2475.47 368.674C2359.29 381.226 2259.77 415.727 2185.33 473.971C2009.52 611.266 1945.5 796.905 2035 1067.22C2119.9 1323.59 2363.11 1660.29 2919.98 1984.9C2925.36 1988.04 2930.56 1991.25 2935.96 1994.43"
							stroke="white"
							strokeWidth="1.27733"
							strokeMiterlimit="10"
						/>
						<path
							d="M4337.92 808.59C4337.84 808.545 4337.78 808.53 4337.71 808.501C3811.51 586.363 3175.51 367.157 2659.06 342.853C2594.09 339.671 2531.72 343.089 2472.82 349.883C2357.19 363.234 2257.6 398.536 2182.04 456.749C2003.52 594.075 1934.69 783.723 2015.64 1052C2094.54 1313.45 2335.7 1653.77 2895.76 1979.36C2901.15 1982.49 2906.37 1985.72 2911.79 1988.9"
							stroke="white"
							strokeWidth="1.212"
							strokeMiterlimit="10"
						/>
						<path
							d="M4339.9 788.81C4339.82 788.765 4339.76 788.75 4339.69 788.721C3807.23 560.322 3174.69 344.446 2656.89 322.821C2591.81 319.994 2529.29 323.828 2470.17 331.095C2355.08 345.231 2255.41 381.331 2178.74 439.544C1997.53 576.87 1923.71 770.544 1996.29 1036.78C2068.96 1303.33 2308.3 1647.27 2871.55 1973.83C2876.94 1976.97 2882.17 1980.21 2887.62 1983.38"
							stroke="white"
							strokeWidth="1.15733"
							strokeMiterlimit="10"
						/>
						<path
							d="M4341.88 769.026C4341.78 768.981 4341.73 768.967 4341.66 768.938C3802.93 534.278 3173.87 321.746 2654.72 302.787C2589.54 300.315 2526.87 304.563 2467.53 312.289C2352.98 327.223 2253.24 364.122 2175.46 422.32C1991.54 559.677 1912.53 757.363 1976.93 1021.57C2043.15 1293.24 2280.89 1640.75 2847.31 1968.29C2852.75 1971.44 2857.99 1974.68 2863.44 1977.86"
							stroke="white"
							strokeWidth="1.092"
							strokeMiterlimit="10"
						/>
						<path
							d="M4343.85 749.243C4343.76 749.198 4343.71 749.183 4343.64 749.154C3798.64 508.219 3173.05 299.032 2652.53 282.766C2587.27 280.62 2524.44 285.281 2464.88 293.497C2350.87 309.216 2251.06 346.929 2172.15 405.099C1985.54 542.468 1901.18 744.136 1957.57 1006.34C2017.1 1283.14 2253.48 1634.23 2823.09 1962.75C2828.54 1965.89 2833.79 1969.15 2839.27 1972.33"
							stroke="white"
							strokeWidth="1.03733"
							strokeMiterlimit="10"
						/>
						<path
							d="M4345.83 729.464C4345.74 729.419 4345.69 729.404 4345.62 729.361C3794.35 482.179 3172.23 276.337 2650.36 262.735C2585 260.944 2522.02 266.021 2462.23 274.709C2348.76 291.199 2248.89 329.725 2168.87 387.88C1979.55 525.281 1889.66 730.841 1938.21 991.135C1990.8 1273.01 2226.07 1627.72 2798.88 1957.21C2804.34 1960.37 2809.61 1963.62 2815.1 1966.81"
							stroke="white"
							strokeWidth="0.972"
							strokeMiterlimit="10"
						/>
						<path
							d="M4347.81 709.678C4347.71 709.633 4347.65 709.619 4347.59 709.575C3790.07 456.118 3171.4 253.637 2648.17 242.698C2582.72 241.263 2519.59 246.738 2459.57 255.901C2346.65 273.159 2246.72 312.531 2165.57 370.655C1973.56 508.07 1877.98 717.434 1918.86 975.909C1964.25 1262.83 2198.66 1621.2 2774.66 1951.68C2780.14 1954.84 2785.41 1958.09 2790.93 1961.29"
							stroke="white"
							strokeWidth="0.906667"
							strokeMiterlimit="10"
						/>
						<path
							d="M4349.78 689.894C4349.69 689.849 4349.63 689.835 4349.56 689.791C3785.77 430.073 3170.58 230.922 2646 222.663C2580.45 221.568 2517.16 227.444 2456.92 237.109C2344.53 255.136 2244.53 295.322 2162.28 353.432C1967.57 490.877 1866.13 703.911 1899.5 960.684C1937.43 1252.56 2171.26 1614.69 2750.42 1946.14C2755.93 1949.29 2761.22 1952.56 2766.75 1955.76"
							stroke="white"
							strokeWidth="0.852"
							strokeMiterlimit="10"
						/>
						<path
							d="M4351.76 670.109C4351.66 670.049 4351.61 670.049 4351.53 670.006C3781.48 404.027 3169.76 208.22 2643.82 202.64C2578.18 201.885 2514.73 208.161 2454.27 218.315C2342.43 237.098 2242.36 278.111 2158.99 336.206C1961.58 473.666 1854.15 690.238 1880.14 945.471C1910.37 1242.19 2143.86 1608.17 2726.21 1940.61C2731.73 1943.76 2737.03 1947.03 2742.58 1950.23"
							stroke="white"
							strokeWidth="0.786667"
							strokeMiterlimit="10"
						/>
						<path
							d="M4353.74 650.33C4353.64 650.271 4353.58 650.271 4353.51 650.227C3777.19 377.973 3168.94 185.511 2641.64 182.611C2575.9 182.21 2512.3 188.872 2451.62 199.513C2340.32 219.051 2240.18 260.924 2155.69 319.003C1955.57 456.477 1842.03 676.38 1860.78 930.251C1883.07 1231.69 2116.45 1601.67 2701.98 1935.07C2707.52 1938.23 2712.84 1941.5 2718.41 1944.72"
							stroke="white"
							strokeWidth="0.732"
							strokeMiterlimit="10"
						/>
						<path
							d="M4355.71 630.548C4355.62 630.488 4355.56 630.488 4355.49 630.445C3772.9 351.928 3168.13 162.812 2639.46 162.576C2573.63 162.518 2509.86 169.444 2448.97 180.722C2338.17 200.763 2238.01 243.716 2152.4 301.783C1949.58 439.271 1829.79 662.34 1841.43 915.028C1855.51 1221.03 2089.04 1595.15 2677.77 1929.54C2683.32 1932.69 2688.65 1935.98 2694.24 1939.19"
							stroke="white"
							strokeWidth="0.666667"
							strokeMiterlimit="10"
						/>
						<path
							d="M4357.69 610.766C4357.59 610.707 4357.53 610.692 4357.46 610.663C3768.6 325.887 3167.3 140.116 2637.29 142.557C2571.36 142.839 2507.43 150.209 2446.32 161.932C2336.06 182.831 2235.83 226.525 2149.1 284.561C1943.58 422.079 1817.47 648.08 1822.07 899.819C1827.75 1210.19 2061.63 1588.64 2653.53 1924.01C2659.12 1927.16 2664.46 1930.45 2670.08 1933.66"
							stroke="white"
							strokeWidth="0.612"
							strokeMiterlimit="10"
						/>
						<path
							d="M4359.67 590.981C4359.57 590.922 4359.51 590.907 4359.44 590.862C3764.32 299.825 3166.48 117.4 2635.1 122.521C2569.08 123.157 2505.01 130.972 2443.68 143.125C2333.96 164.882 2233.65 209.315 2145.81 267.337C1937.59 404.871 1805.05 633.579 1802.71 884.593C1799.79 1199.15 2034.22 1582.12 2629.32 1918.46C2634.91 1921.63 2640.26 1924.93 2645.91 1928.14"
							stroke="white"
							strokeWidth="0.546667"
							strokeMiterlimit="10"
						/>
						<path
							d="M2453.66 4214.39C2577.88 4208.84 2693.74 4052.86 2652.63 3863.11C2599.57 3618.2 2359.32 3500.91 2182.87 3484.69C1797 3449.21 1554.7 3793.52 1484.37 4280.33C1470.64 4375.34 1466.91 4476.12 1463.55 4581.11C1441.49 5269.63 1670.53 5992.11 1670.53 5992.11C1772.36 6325.96 1885.78 6612.15 2043.54 6909.75"
							stroke="white"
							strokeWidth="0.546667"
							strokeMiterlimit="10"
						/>
						<path
							d="M2453.3 4225.98C2480.86 4224.89 2507.89 4216.6 2532.89 4202.29L2532.9 4202.29C2576.9 4177.15 2614.83 4133.49 2638.33 4077.6C2666.66 4010.86 2673.83 3929.01 2652.17 3843.27C2590.97 3600.84 2356.3 3484.03 2177.73 3468.04C1788.4 3433.11 1542.44 3781.84 1471.01 4274.79C1457.23 4369.94 1453.36 4470.86 1449.98 4575.96C1448.79 4613.3 1448.33 4650.75 1448.52 4688.2C1451.96 5334.42 1653.34 5975.81 1657.34 5988.92C1760.45 6326.94 1876.62 6618.23 2036.95 6918.19"
							stroke="white"
							strokeWidth="0.612"
							strokeMiterlimit="10"
						/>
						<path
							d="M2452.94 4237.57C2482.71 4236.51 2511.78 4227.35 2538.39 4211.37L2538.4 4211.37L2538.4 4211.36C2585.03 4183.52 2624.39 4135.01 2647.25 4073.23C2674.43 3999.8 2677.25 3912.46 2651.71 3823.4C2582.96 3583.52 2353.29 3467.14 2172.58 3451.39C1779.81 3417 1530.18 3770.16 1457.65 4269.22C1443.81 4364.54 1439.79 4465.59 1436.41 4570.81C1435.22 4608.18 1434.75 4645.69 1434.94 4683.2C1438.28 5323.04 1636.14 5959.49 1644.14 5985.72C1748.53 6327.91 1867.46 6624.29 2030.36 6926.61"
							stroke="white"
							strokeWidth="0.666667"
							strokeMiterlimit="10"
						/>
						<path
							d="M2452.57 4249.17C2484.57 4248.13 2515.66 4238.08 2543.9 4220.45L2543.91 4220.45L2543.92 4220.45C2593.17 4189.89 2634.05 4136.62 2656.18 4068.89C2682.34 3988.85 2680.79 3896.01 2651.27 3803.56C2575.47 3566.19 2350.27 3450.24 2167.44 3434.73C1771.22 3400.92 1517.92 3758.49 1444.29 4263.66C1430.41 4359.14 1426.23 4460.32 1422.85 4565.66C1421.64 4603.08 1421.18 4640.63 1421.36 4678.21C1424.6 5311.67 1618.95 5943.2 1630.94 5982.53C1736.6 6328.91 1858.31 6630.35 2023.76 6935.05"
							stroke="white"
							strokeWidth="0.732"
							strokeMiterlimit="10"
						/>
						<path
							d="M2452.21 4260.75C2486.42 4259.75 2519.56 4248.81 2549.42 4229.54L2549.42 4229.52L2549.43 4229.52C2601.3 4196.25 2643.72 4138.21 2665.11 4064.52C2690.24 3977.9 2684.43 3879.56 2650.81 3783.7C2568.44 3548.8 2347.25 3433.35 2162.3 3418.07C1762.62 3384.81 1505.67 3746.81 1430.93 4258.11C1416.99 4353.75 1412.67 4455.05 1409.28 4560.49C1408.08 4597.97 1407.61 4635.57 1407.79 4673.21C1410.92 5300.31 1601.75 5926.89 1617.75 5979.33C1724.68 6329.88 1849.15 6636.42 2017.17 6943.48"
							stroke="white"
							strokeWidth="0.797333"
							strokeMiterlimit="10"
						/>
						<path
							d="M2451.85 4272.34C2488.28 4271.38 2523.45 4259.54 2554.93 4238.61L2554.94 4238.61L2554.95 4238.6C2609.44 4202.61 2653.41 4139.83 2674.03 4060.17C2698.16 3966.96 2688.17 3863.16 2650.35 3763.83C2561.8 3531.35 2344.24 3416.46 2157.16 3401.42C1754.02 3368.71 1493.41 3735.15 1417.57 4252.54C1403.58 4348.35 1399.11 4449.78 1395.72 4555.34C1394.51 4592.85 1394.03 4630.51 1394.21 4668.22C1397.24 5288.92 1584.55 5910.58 1604.55 5976.13C1712.76 6330.87 1840 6642.49 2010.58 6951.92"
							stroke="white"
							strokeWidth="0.862667"
							strokeMiterlimit="10"
						/>
						<path
							d="M2451.49 4283.93C2490.14 4283 2527.35 4270.27 2560.43 4247.7L2560.45 4247.69L2560.46 4247.69C2617.57 4208.97 2663.1 4141.44 2682.96 4055.8C2706.1 3956.02 2692.02 3846.75 2649.9 3743.98C2555.52 3513.78 2341.22 3399.57 2152.01 3384.76C1745.43 3352.61 1481.15 3723.47 1404.21 4246.99C1390.16 4342.95 1385.55 4444.51 1382.16 4550.19C1380.95 4587.74 1380.46 4625.46 1380.63 4663.22C1383.56 5277.56 1567.36 5894.27 1591.36 5972.94C1700.84 6331.85 1830.83 6648.54 2004 6960.36"
							stroke="white"
							strokeWidth="0.917333"
							strokeMiterlimit="10"
						/>
						<path
							d="M2451.12 4295.52C2491.99 4294.62 2531.24 4281 2565.94 4256.77L2565.95 4256.77L2565.96 4256.76C2625.71 4215.33 2672.8 4143.06 2691.88 4051.45C2714.03 3945.1 2695.96 3830.32 2649.44 3724.12C2549.54 3496.12 2338.2 3382.67 2146.86 3368.11C1736.83 3336.5 1468.9 3711.79 1390.85 4241.43C1376.75 4337.55 1372 4439.24 1368.58 4545.04C1367.37 4582.62 1366.89 4620.4 1367.05 4658.23C1369.87 5266.19 1550.15 5877.96 1578.15 5969.74C1688.91 6332.83 1821.67 6654.61 1997.41 6968.8"
							stroke="white"
							strokeWidth="0.982667"
							strokeMiterlimit="10"
						/>
						<path
							d="M2450.76 4307.12C2493.84 4306.24 2535.13 4291.75 2571.46 4265.85L2571.47 4265.85L2571.48 4265.84C2633.84 4221.7 2682.5 4144.69 2700.8 4047.09C2721.98 3934.17 2699.99 3813.86 2648.98 3704.28C2543.8 3478.33 2335.19 3365.79 2141.72 3351.46C1728.24 3320.41 1456.64 3700.12 1377.49 4235.88C1363.34 4332.15 1358.43 4433.98 1355.02 4539.88C1353.81 4577.52 1353.31 4615.34 1353.46 4653.23C1356.19 5254.83 1532.96 5861.67 1564.95 5966.55C1676.98 6333.83 1812.52 6660.68 1990.82 6977.24"
							stroke="white"
							strokeWidth="1.048"
							strokeMiterlimit="10"
						/>
						<path
							d="M2450.4 4318.7C2495.7 4317.86 2539.02 4302.48 2576.97 4274.94L2576.98 4274.92L2576.99 4274.92C2641.98 4228.06 2692.21 4146.3 2709.73 4042.73C2729.93 3923.24 2704.12 3797.36 2648.52 3684.41C2538.28 3460.43 2332.18 3348.89 2136.58 3334.79C1719.65 3304.3 1444.37 3688.43 1364.15 4230.31C1349.93 4326.74 1344.88 4428.7 1341.46 4534.73C1340.24 4572.4 1339.74 4610.27 1339.9 4648.22C1342.53 5243.44 1515.76 5845.35 1551.76 5963.35C1665.06 6334.8 1803.36 6666.75 1984.23 6985.67"
							stroke="white"
							strokeWidth="1.10267"
							strokeMiterlimit="10"
						/>
						<path
							d="M2450.04 4330.29C2497.55 4329.48 2542.91 4313.21 2582.48 4284.01L2582.49 4284.01C2582.5 4284.01 2582.5 4283.99 2582.5 4283.99C2650.11 4234.42 2701.93 4147.93 2718.65 4038.37C2737.9 3912.32 2708.32 3780.81 2648.07 3664.56C2532.95 3442.42 2329.16 3332.01 2131.44 3318.14C1711.04 3288.21 1432.11 3676.77 1350.79 4224.74C1336.52 4321.34 1331.31 4423.44 1327.89 4529.58C1326.68 4567.29 1326.17 4605.21 1326.32 4643.23C1328.85 5232.07 1498.57 5829.04 1538.56 5960.15C1653.15 6335.79 1794.21 6672.8 1977.64 6994.11"
							stroke="white"
							strokeWidth="1.168"
							strokeMiterlimit="10"
						/>
						<path
							d="M2449.67 4341.88C2499.41 4341.11 2546.81 4323.94 2587.98 4293.1C2587.99 4293.1 2587.99 4293.08 2587.99 4293.08C2588 4293.08 2588 4293.08 2588.01 4293.07C2658.24 4240.79 2711.64 4149.55 2727.57 4034.01C2745.86 3901.4 2712.59 3764.2 2647.61 3644.7C2527.77 3424.31 2326.15 3315.1 2126.29 3301.49C1702.45 3272.11 1419.85 3665.09 1337.42 4219.19C1323.1 4315.93 1317.75 4418.17 1314.32 4524.43C1313.1 4562.18 1312.6 4600.15 1312.73 4638.23C1315.16 5220.71 1481.37 5812.73 1525.36 5956.95C1641.22 6336.77 1785.04 6678.87 1971.05 7002.54"
							stroke="white"
							strokeWidth="1.23467"
							strokeMiterlimit="10"
						/>
						<path
							d="M2449.31 4353.48C2501.26 4352.74 2550.7 4334.68 2593.5 4302.17L2593.51 4302.17C2593.51 4302.16 2593.52 4302.16 2593.52 4302.16C2666.37 4247.14 2721.37 4151.17 2736.49 4029.65C2753.82 3890.46 2716.94 3747.56 2647.15 3624.84C2522.73 3406.08 2323.13 3298.22 2121.14 3284.82C1693.86 3256.01 1407.6 3653.42 1324.07 4213.63C1309.69 4310.55 1304.19 4412.89 1300.76 4519.26C1299.54 4557.07 1299.03 4595.09 1299.16 4633.25C1301.49 5209.33 1464.18 5796.42 1512.17 5953.76C1629.3 6337.75 1775.88 6684.94 1964.45 7010.97"
							stroke="white"
							strokeWidth="1.3"
							strokeMiterlimit="10"
						/>
						<path
							d="M2448.95 4365.06C2503.12 4364.35 2554.59 4345.41 2599.01 4311.26L2599.02 4311.25C2599.02 4311.25 2599.03 4311.25 2599.03 4311.23C2674.51 4253.51 2731.1 4152.8 2745.43 4025.3C2761.79 3879.54 2721.35 3730.83 2646.69 3604.99C2517.8 3387.74 2320.11 3281.33 2116 3268.17C1685.26 3239.9 1395.34 3641.74 1310.7 4208.08C1296.27 4305.15 1290.64 4407.62 1287.19 4514.11C1285.97 4551.96 1285.46 4590.03 1285.59 4628.24C1287.8 5197.96 1446.97 5780.11 1498.97 5950.56C1617.37 6338.74 1766.73 6690.99 1957.86 7019.41"
							stroke="white"
							strokeWidth="1.35467"
							strokeMiterlimit="10"
						/>
						<path
							d="M2448.58 4376.65C2504.99 4375.97 2558.48 4356.14 2604.52 4320.33L2604.53 4320.33C2604.54 4320.32 2604.54 4320.32 2604.55 4320.32C2682.64 4259.87 2740.83 4154.42 2754.35 4020.93C2769.77 3868.61 2725.83 3714.03 2646.23 3585.12C2512.95 3369.28 2317.11 3264.44 2110.86 3251.52C1676.67 3223.79 1383.09 3630.06 1297.35 4202.51C1282.87 4299.74 1277.08 4402.34 1273.63 4508.96C1272.4 4546.84 1271.89 4584.98 1272 4623.25C1274.13 5186.59 1429.79 5763.81 1485.77 5947.36C1605.46 6339.71 1757.57 6697.06 1951.28 7027.84"
							stroke="white"
							strokeWidth="1.42"
							strokeMiterlimit="10"
						/>
						<path
							d="M2448.22 4388.25C2506.84 4387.59 2562.37 4366.89 2610.02 4329.43L2610.02 4329.41L2610.04 4329.41C2610.04 4329.39 2610.06 4329.39 2610.06 4329.39C2690.78 4266.24 2750.56 4156.04 2763.27 4016.59C2777.75 3857.68 2730.36 3697.17 2645.78 3565.28C2508.2 3350.73 2314.09 3247.54 2105.72 3234.86C1668.08 3207.69 1370.83 3618.4 1283.98 4196.97C1269.45 4294.35 1263.52 4397.08 1260.05 4503.81C1258.83 4541.73 1258.31 4579.92 1258.43 4618.26C1260.45 5175.23 1412.58 5747.51 1472.57 5944.17C1593.53 6340.71 1748.42 6703.13 1944.68 7036.28"
							stroke="white"
							strokeWidth="1.48533"
							strokeMiterlimit="10"
						/>
						<path
							d="M2447.86 4399.83C2508.69 4399.23 2566.27 4377.62 2615.54 4338.5L2615.55 4338.48C2615.55 4338.48 2615.57 4338.48 2615.57 4338.47C2698.91 4272.6 2760.3 4157.67 2772.2 4012.22C2785.73 3846.76 2734.96 3680.24 2645.33 3545.42C2503.5 3332.1 2311.07 3230.65 2100.58 3218.2C1659.48 3191.59 1358.57 3606.72 1270.63 4191.4C1256.04 4288.94 1249.96 4391.81 1246.49 4498.65C1245.26 4536.63 1244.74 4574.86 1244.85 4613.27C1246.77 5163.85 1395.38 5731.19 1459.38 5940.99C1581.61 6341.69 1739.26 6709.2 1938.1 7044.72"
							stroke="white"
							strokeWidth="1.54"
							strokeMiterlimit="10"
						/>
						<path
							d="M2447.5 4411.43C2510.55 4410.85 2570.16 4388.35 2621.05 4347.57L2621.06 4347.57C2621.07 4347.56 2621.07 4347.56 2621.08 4347.56C2707.05 4278.97 2770.03 4159.29 2781.12 4007.87C2793.71 3835.83 2739.6 3663.24 2644.87 3525.57C2498.87 3313.36 2308.06 3213.77 2095.43 3201.55C1650.88 3175.5 1346.31 3595.05 1257.26 4185.84C1242.62 4283.54 1236.39 4386.54 1232.93 4493.5C1231.7 4531.51 1231.16 4569.8 1231.27 4608.27C1233.09 5152.48 1378.19 5714.88 1446.18 5937.79C1569.68 6342.67 1730.1 6715.26 1931.51 7053.16"
							stroke="white"
							strokeWidth="1.60533"
							strokeMiterlimit="10"
						/>
						<path
							d="M2447.13 4423.02C2512.4 4422.47 2574.06 4399.08 2626.55 4356.66C2626.57 4356.66 2626.57 4356.65 2626.58 4356.65C2626.58 4356.65 2626.59 4356.65 2626.59 4356.63C2715.17 4285.32 2779.77 4160.91 2790.04 4003.5C2801.7 3824.9 2744.28 3646.16 2644.41 3505.71C2494.27 3294.55 2305.04 3196.88 2090.28 3184.89C1642.29 3159.4 1334.05 3583.37 1243.9 4180.29C1229.21 4278.14 1222.83 4381.28 1219.36 4488.35C1218.12 4526.4 1217.59 4564.74 1217.7 4603.28C1219.4 5141.11 1360.99 5698.57 1432.98 5934.59C1557.76 6343.66 1720.95 6721.33 1924.92 7061.59"
							stroke="white"
							strokeWidth="1.67067"
							strokeMiterlimit="10"
						/>
						<path
							d="M2446.77 4434.61C2514.26 4434.09 2577.95 4409.81 2632.06 4365.74L2632.09 4365.74C2632.09 4365.72 2632.1 4365.72 2632.11 4365.7L2632.1 4365.7C2723.32 4291.68 2789.51 4162.53 2798.96 3999.15C2809.68 3813.97 2749.02 3629.02 2643.96 3485.84C2489.71 3275.65 2302.02 3179.98 2085.14 3168.24C1633.7 3143.3 1321.8 3571.69 1230.56 4174.72C1215.8 4272.74 1209.28 4376.01 1205.8 4483.2C1204.56 4521.28 1204.02 4559.68 1204.11 4598.28C1205.74 5129.73 1343.8 5682.26 1419.79 5931.4C1545.85 6344.64 1711.79 6727.4 1918.33 7070.03"
							stroke="white"
							strokeWidth="1.736"
							strokeMiterlimit="10"
						/>
						<path
							d="M2446.41 4446.19C2516.11 4445.7 2581.83 4420.54 2637.58 4374.82L2637.59 4374.81C2637.61 4374.79 2637.61 4374.79 2637.62 4374.79C2731.44 4298.05 2799.25 4164.14 2807.89 3994.78C2817.67 3803.03 2753.79 3611.8 2643.5 3465.99C2485.17 3256.66 2299.01 3163.08 2079.99 3151.58C1625.1 3127.19 1309.54 3560.03 1217.2 4169.17C1202.38 4267.35 1195.72 4370.74 1192.24 4478.03C1190.99 4516.17 1190.46 4554.61 1190.54 4593.29C1192.06 5118.36 1326.6 5665.97 1406.59 5928.2C1533.92 6345.62 1702.64 6733.45 1911.74 7078.47"
							stroke="white"
							strokeWidth="1.79067"
							strokeMiterlimit="10"
						/>
						<path
							d="M2446.04 4457.78C2517.97 4457.32 2585.73 4431.29 2643.09 4383.89C2643.1 4383.89 2643.1 4383.88 2643.11 4383.88C2643.11 4383.88 2643.13 4383.88 2643.13 4383.86C2739.58 4304.41 2808.99 4165.77 2816.82 3990.43C2825.66 3792.11 2758.59 3594.51 2643.04 3446.13C2480.67 3237.61 2295.99 3146.2 2074.85 3134.93C1616.51 3111.09 1297.28 3548.35 1203.84 4163.6C1188.98 4261.95 1182.16 4365.46 1178.66 4472.88C1177.43 4511.05 1176.88 4549.55 1176.95 4588.28C1178.38 5106.99 1309.4 5649.65 1393.39 5925C1522 6346.6 1693.47 6739.52 1905.14 7086.89"
							stroke="white"
							strokeWidth="1.85733"
							strokeMiterlimit="10"
						/>
						<path
							d="M2445.68 4469.37C2519.82 4468.96 2589.62 4442.02 2648.6 4392.98C2648.61 4392.98 2648.61 4392.97 2648.62 4392.97C2648.62 4392.95 2648.63 4392.95 2648.65 4392.94C2747.71 4310.78 2818.74 4167.38 2825.74 3986.07C2833.65 3781.17 2763.43 3577.17 2642.59 3426.28C2476.17 3218.49 2292.98 3129.3 2069.71 3118.27C1607.91 3094.99 1285.03 3536.67 1190.48 4158.06C1175.56 4256.55 1168.6 4360.2 1165.1 4467.73C1163.85 4505.95 1163.31 4544.5 1163.38 4583.3C1164.7 5095.63 1292.2 5633.35 1380.19 5921.81C1510.07 6347.58 1684.31 6745.59 1898.55 7095.33"
							stroke="white"
							strokeWidth="1.92267"
							strokeMiterlimit="10"
						/>
						<path
							d="M2445.32 4480.97C2521.68 4480.58 2593.52 4452.76 2654.11 4402.06L2654.12 4402.06L2654.13 4402.05C2654.14 4402.05 2654.14 4402.05 2654.15 4402.03C2755.85 4317.15 2828.77 4169.01 2834.67 3981.72C2841.96 3770.27 2768.31 3559.75 2642.13 3406.42C2471.69 3199.31 2289.97 3112.41 2064.57 3101.62C1599.32 3078.89 1272.76 3525 1177.12 4152.5C1162.15 4251.15 1155.04 4354.93 1151.54 4462.58C1150.29 4500.84 1149.74 4539.45 1149.81 4578.3C1151.02 5084.25 1275.01 5617.04 1367 5918.62C1498.16 6348.58 1675.16 6751.64 1891.97 7103.77"
							stroke="white"
							strokeWidth="1.97733"
							strokeMiterlimit="10"
						/>
						<path
							d="M2444.95 4492.55C2523.53 4492.2 2597.41 4463.48 2659.62 4411.13L2659.63 4411.13C2659.65 4411.12 2659.66 4411.12 2659.66 4411.1C2763.98 4323.51 2838.5 4170.62 2843.59 3977.35C2849.93 3759.33 2773.2 3542.27 2641.67 3386.56C2467.23 3180.06 2286.95 3095.52 2059.41 3084.97C1590.72 3062.79 1260.5 3513.31 1163.76 4146.93C1148.73 4245.74 1141.47 4349.66 1137.96 4457.41C1136.72 4495.72 1136.16 4534.38 1136.22 4573.31C1137.33 5072.88 1257.81 5600.72 1353.79 5915.41C1486.23 6349.55 1666 6757.71 1885.37 7112.2"
							stroke="white"
							strokeWidth="2.04267"
							strokeMiterlimit="10"
						/>
						<path
							d="M2444.59 4504.15C2525.39 4503.82 2601.31 4474.22 2665.13 4420.22C2665.14 4420.22 2665.14 4420.21 2665.15 4420.21C2665.15 4420.19 2665.17 4420.19 2665.18 4420.19C2772.12 4329.86 2848.22 4172.24 2852.51 3973C2857.9 3748.39 2778.13 3524.72 2641.21 3366.71C2462.76 3160.75 2283.93 3078.64 2054.27 3068.3C1582.13 3046.7 1248.24 3501.66 1150.4 4141.38C1135.33 4240.34 1127.92 4344.4 1124.4 4452.27C1123.15 4490.62 1122.59 4529.32 1122.65 4568.31C1123.66 5061.52 1240.62 5584.42 1340.6 5912.22C1474.31 6350.53 1656.85 6763.78 1878.79 7120.64"
							stroke="white"
							strokeWidth="2.108"
							strokeMiterlimit="10"
						/>
						<path
							d="M2444.23 4515.73C2527.24 4515.44 2605.19 4484.94 2670.64 4429.29C2670.65 4429.29 2670.66 4429.29 2670.66 4429.28C2670.68 4429.28 2670.68 4429.28 2670.69 4429.26C2780.25 4336.22 2857.95 4173.85 2861.44 3968.63C2865.87 3737.45 2783.08 3507.11 2640.76 3346.85C2458.3 3141.39 2280.93 3061.73 2049.13 3051.65C1573.54 3030.59 1235.99 3489.97 1137.04 4135.81C1121.91 4234.94 1114.37 4339.12 1110.84 4447.11C1109.59 4485.49 1109.02 4524.26 1109.08 4563.32C1109.98 5050.13 1223.42 5568.12 1327.41 5909.02C1462.39 6351.52 1647.69 6769.85 1872.2 7129.07"
							stroke="white"
							strokeWidth="2.17333"
							strokeMiterlimit="10"
						/>
						<path
							d="M2443.87 4527.32C2529.1 4527.07 2609.08 4495.69 2676.16 4438.38L2676.17 4438.37C2676.18 4438.35 2676.19 4438.35 2676.19 4438.34C2788.39 4342.59 2867.68 4175.47 2870.36 3964.28C2873.83 3726.51 2788.05 3489.44 2640.3 3327C2453.83 3121.97 2277.9 3044.84 2043.99 3035C1564.93 3014.48 1223.73 3478.3 1123.68 4130.26C1108.5 4229.54 1100.8 4333.85 1097.27 4441.96C1096.01 4480.39 1095.44 4519.2 1095.49 4558.32C1096.3 5038.77 1206.23 5551.81 1314.21 5905.82C1450.46 6352.5 1638.53 6775.9 1865.61 7137.51"
							stroke="white"
							strokeWidth="2.228"
							strokeMiterlimit="10"
						/>
						<path
							d="M2443.51 4538.91C2530.95 4538.69 2612.98 4506.42 2681.67 4447.45C2681.67 4447.45 2681.68 4447.44 2681.69 4447.44C2681.69 4447.42 2681.7 4447.42 2681.71 4447.42C2796.52 4348.95 2877.4 4177.08 2879.28 3959.91C2881.81 3715.57 2793.04 3471.71 2639.85 3307.13C2449.35 3102.49 2274.89 3027.95 2038.85 3018.33C1556.34 2998.38 1211.47 3466.62 1110.33 4124.7C1095.09 4224.15 1087.24 4328.58 1083.71 4436.79C1082.45 4475.28 1081.87 4514.13 1081.92 4553.33C1082.62 5027.4 1189.03 5535.49 1301.01 5902.62C1438.55 6353.49 1629.38 6781.97 1859.02 7145.94"
							stroke="white"
							strokeWidth="2.29333"
							strokeMiterlimit="10"
						/>
						<path
							d="M2443.14 4550.5C2532.81 4550.31 2616.87 4517.15 2687.17 4456.54C2687.18 4456.54 2687.18 4456.53 2687.2 4456.53C2687.21 4456.51 2687.21 4456.51 2687.22 4456.5C2804.66 4355.31 2887.12 4178.71 2888.2 3955.56C2889.77 3704.64 2798.05 3453.93 2639.39 3287.29C2444.88 3082.98 2271.86 3011.06 2033.69 3001.68C1547.74 2982.27 1199.22 3454.94 1096.97 4119.15C1081.67 4218.74 1073.68 4323.31 1070.14 4431.64C1068.88 4470.16 1068.3 4509.07 1068.33 4548.32C1068.95 5016.03 1171.82 5519.18 1287.81 5899.43C1426.62 6354.46 1620.21 6788.04 1852.43 7154.38"
							stroke="white"
							strokeWidth="2.35867"
							strokeMiterlimit="10"
						/>
						<path
							d="M2442.78 4562.09C2534.67 4561.93 2620.77 4527.89 2692.68 4465.62C2692.69 4465.62 2692.7 4465.6 2692.7 4465.6C2692.72 4465.59 2692.73 4465.59 2692.73 4465.57C2812.79 4361.68 2896.85 4180.32 2897.14 3951.2C2897.74 3693.7 2803.07 3436.1 2638.94 3267.43C2440.41 3063.42 2268.86 2994.16 2028.55 2985.03C1539.15 2966.17 1186.96 3443.28 1083.61 4113.58C1068.26 4213.34 1060.12 4318.05 1056.57 4426.5C1055.31 4465.05 1054.73 4504.01 1054.76 4543.34C1055.27 5004.65 1154.63 5502.88 1274.62 5896.23C1414.69 6355.46 1611.06 6794.1 1845.85 7162.81"
							stroke="white"
							strokeWidth="2.41333"
							strokeMiterlimit="10"
						/>
						<path
							d="M2442.42 4573.68C2536.53 4573.55 2624.65 4538.62 2698.2 4474.69L2698.22 4474.69C2698.22 4474.68 2698.24 4474.68 2698.25 4474.66C2820.93 4368.03 2906.57 4181.94 2906.06 3946.85C2905.72 3682.76 2808.12 3418.21 2638.48 3247.56C2435.92 3043.82 2265.84 2977.27 2023.41 2968.36C1530.56 2950.08 1174.7 3431.6 1070.25 4108.02C1054.85 4207.94 1046.57 4312.78 1043.01 4421.33C1041.74 4459.93 1041.16 4498.96 1041.18 4538.34C1041.59 4993.28 1137.43 5486.56 1261.41 5893.04C1402.77 6356.44 1601.9 6800.16 1839.24 7171.25"
							stroke="white"
							strokeWidth="2.47867"
							strokeMiterlimit="10"
						/>
						<path
							d="M2442.05 4585.28C2538.38 4585.17 2628.54 4549.36 2703.7 4483.78C2703.72 4483.78 2703.72 4483.77 2703.73 4483.77C2703.74 4483.76 2703.74 4483.76 2703.75 4483.74C2829.05 4374.4 2916.3 4183.56 2914.98 3942.49C2913.68 3671.83 2813.17 3400.27 2638.02 3227.72C2431.43 3024.17 2262.83 2960.39 2018.27 2951.72C1521.97 2933.99 1162.44 3419.93 1056.89 4102.47C1041.44 4202.55 1033 4307.52 1029.43 4416.19C1028.18 4454.83 1027.59 4493.91 1027.6 4533.36C1027.91 4981.92 1120.24 5470.28 1248.22 5889.85C1390.86 6357.42 1592.74 6806.24 1832.65 7179.69"
							stroke="white"
							strokeWidth="2.54533"
							strokeMiterlimit="10"
						/>
						<path
							d="M2441.69 4596.87C2540.24 4596.81 2632.44 4560.09 2709.21 4492.86C2709.23 4492.86 2709.24 4492.84 2709.24 4492.84C2709.25 4492.83 2709.26 4492.83 2709.26 4492.81C2837.19 4380.77 2926.02 4185.17 2923.91 3938.14C2921.65 3660.89 2818.24 3382.29 2637.56 3207.86C2426.92 3004.49 2259.81 2943.51 2013.13 2935.07C1513.36 2917.88 1150.19 3408.25 1043.53 4096.91C1028.02 4197.14 1019.44 4302.25 1015.87 4411.04C1014.61 4449.71 1014.01 4488.85 1014.02 4528.35C1014.23 4970.54 1103.04 5453.96 1235.03 5886.65C1378.93 6358.41 1583.59 6812.31 1826.07 7188.13"
							stroke="white"
							strokeWidth="2.61067"
							strokeMiterlimit="10"
						/>
						<path
							d="M2441.33 4608.45C2542.09 4608.42 2636.33 4570.83 2714.72 4501.94C2714.73 4501.94 2714.74 4501.93 2714.76 4501.93C2714.76 4501.91 2714.77 4501.91 2714.78 4501.9C2845.32 4387.13 2935.74 4186.78 2932.83 3933.77C2929.61 3649.95 2823.31 3364.26 2637.11 3188C2422.4 2984.77 2256.79 2926.6 2007.98 2918.4C1504.77 2901.78 1137.93 3396.57 1030.17 4091.36C1014.61 4191.74 1005.88 4296.97 1002.31 4405.88C1001.04 4444.6 1000.44 4483.78 1000.45 4523.36C1000.55 4959.17 1085.85 5437.65 1221.83 5883.45C1367 6359.38 1574.43 6818.36 1819.48 7196.56"
							stroke="white"
							strokeWidth="2.66533"
							strokeMiterlimit="10"
						/>
						<path
							d="M2440.97 4620.05C2543.95 4620.05 2640.23 4581.56 2720.24 4511.02C2720.24 4511.02 2720.25 4511.01 2720.26 4511.01C2720.27 4510.99 2720.28 4510.99 2720.29 4510.98C2853.46 4393.5 2945.47 4188.4 2941.75 3929.42C2937.59 3639.03 2828.4 3346.18 2636.65 3168.15C2417.88 2965.01 2253.78 2909.72 2002.83 2901.75C1496.18 2885.68 1125.67 3384.91 1016.81 4085.79C995.641 4222.1 986.866 4367.24 986.866 4518.36C986.866 4947.81 1068.64 5421.34 1208.62 5880.24L1208.62 5880.26C1355.08 6360.37 1565.28 6824.43 1812.89 7205"
							stroke="white"
							strokeWidth="2.73067"
							strokeMiterlimit="10"
						/>
						<path
							d="M2208.83 990.836C2204.56 1010.34 2180.32 1050.75 2245.89 1169.49C2341.75 1343.12 2897.8 1757.67 3015.34 1803.2C2915.09 1742.77 2663.61 1576.85 2466.72 1382.69C2328.91 1246.77 2221.73 1088.89 2208.83 990.836Z"
							fill="white"
						/>
					</g>
				</svg> */}
				<div className="you-get-access blockElement" id="you-get-access">
					<div className="container">
						<div className="inner-you-get blockElement">
							<h2>You Get access to</h2>
							<Slider {...settingsYouGetAccess}>
								<div className="slide-item">
									<div className="single-you-get blockElement">
										<div className="inner-single-slider-get blockElement">
											<div className="inner-inner-single-slide-get blockElement">
												<img src={GetAccess1} alt="" />
												<div className="content-get">
													<h5>World's First Elite OTT Streaming Platform</h5>
													<p>Enhance customer experience with our special feature of publishing your app as an OTT application</p>
													<a href="https://app.streamefy.com/?tabId=1" className="cmn-btn">
														<span>
															<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
																<path
																	d="M8.2821 16L15.7819 8.52686C15.9216 8.38684 16 8.19743 16 8C16 7.80257 15.9216 7.61316 15.7819 7.47314L8.2821 -3.3736e-07L7.22462 1.05371L13.442 7.24895L-3.16862e-07 7.24895L-3.82194e-07 8.74358L13.442 8.74358L7.22462 14.9388L8.2821 16Z"
																	fill="white"
																/>
															</svg>
															try for free
														</span>
													</a>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="slide-item">
									<div className="single-you-get blockElement">
										<div className="inner-single-slider-get blockElement">
											<div className="inner-inner-single-slide-get blockElement">
												<img src={GetAccess2} alt="" />
												<div className="content-get">
													<h5>Custom Branding</h5>
													<p>Provide your brand details to showcase your work through our platform.</p>
													<a href="https://app.streamefy.com/?tabId=1" className="cmn-btn">
														<span>
															<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
																<path
																	d="M8.2821 16L15.7819 8.52686C15.9216 8.38684 16 8.19743 16 8C16 7.80257 15.9216 7.61316 15.7819 7.47314L8.2821 -3.3736e-07L7.22462 1.05371L13.442 7.24895L-3.16862e-07 7.24895L-3.82194e-07 8.74358L13.442 8.74358L7.22462 14.9388L8.2821 16Z"
																	fill="white"
																/>
															</svg>
															try for free
														</span>
													</a>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="slide-item">
									<div className="single-you-get blockElement">
										<div className="inner-single-slider-get blockElement">
											<div className="inner-inner-single-slide-get blockElement">
												<img src={you3} alt="" />
												<div className="content-get">
													<h5>Get detailed analytics of your business</h5>
													<p>
														Gain valuable insights through detailed analytics reports, providing in-depth visibility into key business metrics and
														performance indicators.
													</p>
													<a href="https://app.streamefy.com/?tabId=1" className="cmn-btn">
														<span>
															<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
																<path
																	d="M8.2821 16L15.7819 8.52686C15.9216 8.38684 16 8.19743 16 8C16 7.80257 15.9216 7.61316 15.7819 7.47314L8.2821 -3.3736e-07L7.22462 1.05371L13.442 7.24895L-3.16862e-07 7.24895L-3.82194e-07 8.74358L13.442 8.74358L7.22462 14.9388L8.2821 16Z"
																	fill="white"
																/>
															</svg>
															try for free
														</span>
													</a>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="slide-item">
									<div className="single-you-get blockElement">
										<div className="inner-single-slider-get blockElement">
											<div className="inner-inner-single-slide-get blockElement">
												<img src={you4} alt="" />
												<div className="content-get">
													<h5>Enhance privacy with secure, password- protected link sharing</h5>
													<p>Share your project links with your clients by setting the password to ensure the security of client’s videos</p>
													<a href="https://app.streamefy.com/?tabId=1" className="cmn-btn">
														<span>
															<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
																<path
																	d="M8.2821 16L15.7819 8.52686C15.9216 8.38684 16 8.19743 16 8C16 7.80257 15.9216 7.61316 15.7819 7.47314L8.2821 -3.3736e-07L7.22462 1.05371L13.442 7.24895L-3.16862e-07 7.24895L-3.82194e-07 8.74358L13.442 8.74358L7.22462 14.9388L8.2821 16Z"
																	fill="white"
																/>
															</svg>
															try for free
														</span>
													</a>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="slide-item">
									<div className="single-you-get blockElement">
										<div className="inner-single-slider-get blockElement">
											<div className="inner-inner-single-slide-get blockElement">
												<img src={you5} alt="" />
												<div className="content-get">
													<h5>Premium viewing experience</h5>
													<p>Deliver an exceptional viewing experience that your viewers will adore.</p>
													<a href="https://app.streamefy.com/?tabId=1" className="cmn-btn">
														<span>
															<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
																<path
																	d="M8.2821 16L15.7819 8.52686C15.9216 8.38684 16 8.19743 16 8C16 7.80257 15.9216 7.61316 15.7819 7.47314L8.2821 -3.3736e-07L7.22462 1.05371L13.442 7.24895L-3.16862e-07 7.24895L-3.82194e-07 8.74358L13.442 8.74358L7.22462 14.9388L8.2821 16Z"
																	fill="white"
																/>
															</svg>
															try for free
														</span>
													</a>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="slide-item">
									<div className="single-you-get blockElement">
										<div className="inner-single-slider-get blockElement">
											<div className="inner-inner-single-slide-get blockElement">
												<img src={you6} alt="" />
												<div className="content-get">
													<h5>Craft Projects with step-by-step guided tour</h5>
													<p>
														Effortlessly create and share project events with your clients. Our platform streamlines the process, allowing filmmakers
													</p>
													<a href="https://app.streamefy.com/?tabId=1" className="cmn-btn">
														<span>
															<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
																<path
																	d="M8.2821 16L15.7819 8.52686C15.9216 8.38684 16 8.19743 16 8C16 7.80257 15.9216 7.61316 15.7819 7.47314L8.2821 -3.3736e-07L7.22462 1.05371L13.442 7.24895L-3.16862e-07 7.24895L-3.82194e-07 8.74358L13.442 8.74358L7.22462 14.9388L8.2821 16Z"
																	fill="white"
																/>
															</svg>
															try for free
														</span>
													</a>
												</div>
											</div>
										</div>
									</div>
								</div>
							</Slider>
						</div>
					</div>
				</div>

				<div className="counting-section blockElement">
					<div className="container">
						<div className="inner-counting-section">
							<div className="row">
								<div className="col-md-3">
									<div className="single-counter">
										<h2>
											{overviewData.projectShared[0]} <span>{overviewData.projectShared[1]}</span>
										</h2>
										<p>projects shared</p>
									</div>
								</div>
								<div className="col-md-3">
									<div className="single-counter">
										<h2>
											{overviewData.filmMakers[0]} <span>{overviewData.filmMakers[1]}</span>
										</h2>
										<p>Filmmakers</p>
									</div>
								</div>
								<div className="col-md-3">
									<div className="single-counter">
										<h2>
											{overviewData.dataUploaded[0]} <span>{overviewData.dataUploaded[1]}</span>
										</h2>
										<p>DATA UPLOADED</p>
									</div>
								</div>
								<div className="col-md-3">
									<div className="single-counter">
										<h2>
											{overviewData.clientServed[0]} <span>{overviewData.clientServed[1]}</span>
										</h2>
										<p>CLIENTS SERVED</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<Pricing />

				<div className="show-your-work blockElement">
					<div className="container">
						<div className="inner-showcase">
							<h2>Showcase your work</h2>
							<div className="work-showcase-slide" id="work-slider">
								<Slider {...settingsShowYourWork}>
									<div className="slide-item">
										<div className="single-showcase">
											<div className="main-div-showcase">
												<img src={Showcaseimg1} alt="" />
												<h6>Wedding Films</h6>
											</div>
											<div className="overlay-showcase">
												<h6>Wedding Films</h6>
												<p>
													Wedding filmmakers can effortlessly upload and share their shooted wedding videos on Streamefy, offering clients a
													high-quality, cinematic viewing experience. Clients can access their personalized wedding films directly through the
													platform's OTT service, enjoying seamless streaming and the ability to relive their special moments anytime, anywhere, with
													premium video playback.
												</p>
												<a href="https://app.streamefy.com/?tabId=1" className="cmn-btn">
													<span>
														<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path
																d="M8.2821 16L15.7819 8.52686C15.9216 8.38684 16 8.19743 16 8C16 7.80257 15.9216 7.61316 15.7819 7.47314L8.2821 -3.3736e-07L7.22462 1.05371L13.442 7.24895L-3.16862e-07 7.24895L-3.82194e-07 8.74358L13.442 8.74358L7.22462 14.9388L8.2821 16Z"
																fill="white"
															/>
														</svg>
														try for free
													</span>
												</a>
											</div>
										</div>
									</div>
									<div className="slide-item">
										<div className="single-showcase">
											<div className="main-div-showcase">
												<img src={box2} alt="" />
												<h6>Enterprise Video</h6>
											</div>
											<div className="overlay-showcase">
												<h6>Enterprise Video</h6>
												<p>
													Elevate your corporate storytelling with Streamefy. Upload and securely share company profiles, product demos, and training
													sessions, ensuring professional and accessible content for your team and clients.
												</p>
												<a href="https://app.streamefy.com/?tabId=1" className="cmn-btn">
													<span>
														<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path
																d="M8.2821 16L15.7819 8.52686C15.9216 8.38684 16 8.19743 16 8C16 7.80257 15.9216 7.61316 15.7819 7.47314L8.2821 -3.3736e-07L7.22462 1.05371L13.442 7.24895L-3.16862e-07 7.24895L-3.82194e-07 8.74358L13.442 8.74358L7.22462 14.9388L8.2821 16Z"
																fill="white"
															/>
														</svg>
														try for free
													</span>
												</a>
											</div>
										</div>
									</div>
									<div className="slide-item">
										<div className="single-showcase">
											<div className="main-div-showcase">
												<img src={box3} alt="" />
												<h6>Video Productions</h6>
											</div>
											<div className="overlay-showcase">
												<h6>Video Productions</h6>
												<p>
													Whether you’re creating short films, documentaries, or any other type of video project, Streamefy is the perfect platform to
													showcase your work. Share your creative projects with your audience in high quality, and engage with viewers through secure
													and professional video-sharing.
												</p>
												<a href="https://app.streamefy.com/?tabId=1" className="cmn-btn">
													<span>
														<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path
																d="M8.2821 16L15.7819 8.52686C15.9216 8.38684 16 8.19743 16 8C16 7.80257 15.9216 7.61316 15.7819 7.47314L8.2821 -3.3736e-07L7.22462 1.05371L13.442 7.24895L-3.16862e-07 7.24895L-3.82194e-07 8.74358L13.442 8.74358L7.22462 14.9388L8.2821 16Z"
																fill="white"
															/>
														</svg>
														try for free
													</span>
												</a>
											</div>
										</div>
									</div>
									<div className="slide-item">
										<div className="single-showcase">
											<div className="main-div-showcase">
												<img src={box4} alt="" />
												<h6>School Videos</h6>
											</div>
											<div className="overlay-showcase">
												<h6>School Videos</h6>
												<p>
													Streamefy provides students and parents with easy access to educational videos and school events. Schools can upload
													educational series, event coverage, and promotional content to highlight achievements. This feature fosters greater
													engagement by keeping the school community informed about key activities.
												</p>
												<a href="https://app.streamefy.com/?tabId=1" className="cmn-btn">
													<span>
														<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path
																d="M8.2821 16L15.7819 8.52686C15.9216 8.38684 16 8.19743 16 8C16 7.80257 15.9216 7.61316 15.7819 7.47314L8.2821 -3.3736e-07L7.22462 1.05371L13.442 7.24895L-3.16862e-07 7.24895L-3.82194e-07 8.74358L13.442 8.74358L7.22462 14.9388L8.2821 16Z"
																fill="white"
															/>
														</svg>
														try for free
													</span>
												</a>
											</div>
										</div>
									</div>
								</Slider>
							</div>
						</div>
					</div>
				</div>

				<div className="download-section blockElement">
					<div className="container">
						<div className="inner-download-section">
							<div className="row">
								<div className="col-md-6">
									<div className="left-image-download">
										<img src={TVimg1} alt="" />
									</div>
								</div>
								<div className="col-md-6">
									<div className="right-image-download">
										<h2>Download white label app on:</h2>
										<p>
											Elevate your filmmaking projects with our white-label solution. Design, manage, and deliver your content seamlessly, offering
											your customers an elite viewing experience. With our app, your audience can even enjoy their events on TV.
										</p>
										<div className="buttons-download">
											<a href="">
												<img src={Icon} alt="" />
											</a>
											<a href="">
												<img src={Icon2} alt="" />
											</a>
											<a href="">
												<img src={android_tv} alt="" />
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="testimonials blockElement">
					<div className="heading-testi">
						<div className="container">
							<div className="inner-heading-testi">
								<h2>Words from the professionals</h2>
								<a href="https://app.streamefy.com/?tabId=1" className="cmn-btn">
									<span>
										<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M8.2821 16L15.7819 8.52686C15.9216 8.38684 16 8.19743 16 8C16 7.80257 15.9216 7.61316 15.7819 7.47314L8.2821 -3.3736e-07L7.22462 1.05371L13.442 7.24895L-3.16862e-07 7.24895L-3.82194e-07 8.74358L13.442 8.74358L7.22462 14.9388L8.2821 16Z"
												fill="white"
											/>
										</svg>
										start free trial
									</span>
								</a>
							</div>
							<div className="testimonail-slider" id="testimonial-slider">
								<Slider {...settingsTestimonial}>
									<div className="single-slide">
										<div className="inner-single-slide">
											<div className="image-box-testi">
												<img src={box3} alt="" />
												<div className="name-author-testi">
													<h6>Alex Johnson</h6>
													<p>Marketing Manager</p>
												</div>
											</div>
											<div className="content-testi">
												<p>
													"Streamefy has transformed the art of corporate video sharing. The platform beautifully showcases our content, making it
													both captivating and professional. We love the results!”
												</p>
											</div>
										</div>
									</div>
									<div className="single-slide">
										<div className="inner-single-slide">
											<div className="image-box-testi">
												<img src={box3} alt="" />
												<div className="name-author-testi">
													<h6>Priya Sharma</h6>
													<p>Wedding Filmmaker</p>
												</div>
											</div>
											<div className="content-testi">
												<p>
													"Streamefy has changed how I share wedding films with my clients. The secure sharing and high-quality uploads make my work
													look great. It’s been a great tool for my business!”
												</p>
											</div>
										</div>
									</div>
								</Slider>
							</div>
						</div>
					</div>
				</div>
				<div className="key-features blockElement">
					<div className="container">
						<div className="inner-key-features">
							<h2>Highlighted key features</h2>
							<div className="key-slider" id="key-slider">
								<Slider {...settingsKeyFeatures}>
									<div className="single-key">
										<div className="icon-single-key">
											<img src={key1} alt="" />
										</div>
										<h6>Download high quality videos</h6>
									</div>
									<div className="single-key">
										<div className="icon-single-key">
											<img src={key2} alt="" />
										</div>
										<h6>Secure sharing & delivery</h6>
									</div>
									<div className="single-key">
										<div className="icon-single-key">
											<img src={key3} alt="" />
										</div>
										<h6>Ad free videos</h6>
									</div>

									<div className="single-key">
										<div className="icon-single-key">
											<img src={key4} alt="" />
										</div>
										<h6>OTT App</h6>
									</div>

									<div className="single-key">
										<div className="icon-single-key">
											<img src={key5} alt="" />
										</div>
										<h6>Make your own cloud library</h6>
									</div>
								</Slider>
							</div>
						</div>
					</div>
				</div>

				<div className="faq-section blockElement">
					<div className="container">
						<div className="inner-faq-section">
							<h2>FAQs</h2>
							<div className="faqs-main">
								<div className="single-faq-box">
									<div className="accordion" id="accordionExample">
										<div className="faq-left">
											<div className="accordion-item">
												<h2 className="accordion-header" id="headingOne">
													<button
														className="accordion-button"
														type="button"
														data-bs-toggle="collapse"
														data-bs-target="#collapseOne"
														aria-expanded="true"
														aria-controls="collapseOne"
													>
														<span>01</span>
														Why streamefy?
													</button>
												</h2>
												<div
													id="collapseOne"
													className="accordion-collapse collapse show"
													aria-labelledby="headingOne"
													data-bs-parent="#accordionExample"
												>
													<div className="accordion-body">
														<strong>This is the first item's accordion body.</strong> It is shown by default, until the collapse plugin adds the
														appropriate classNamees that we use to style each element. These classNamees control the overall appearance, as well as
														the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default
														variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the
														transition does limit overflow.
													</div>
												</div>
											</div>
											<div className="accordion-item">
												<h2 className="accordion-header" id="headingTwo">
													<button
														className="accordion-button collapsed"
														type="button"
														data-bs-toggle="collapse"
														data-bs-target="#collapseTwo"
														aria-expanded="false"
														aria-controls="collapseTwo"
													>
														<span>02</span>
														How to create a project on this platform?
													</button>
												</h2>
												<div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
													<div className="accordion-body">
														<strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the
														appropriate classNamees that we use to style each element. These classNamees control the overall appearance, as well as
														the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default
														variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the
														transition does limit overflow.
													</div>
												</div>
											</div>
											<div className="accordion-item">
												<h2 className="accordion-header" id="headingThree">
													<button
														className="accordion-button collapsed"
														type="button"
														data-bs-toggle="collapse"
														data-bs-target="#collapseThree"
														aria-expanded="false"
														aria-controls="collapseThree"
													>
														<span>03</span>
														How can we deliver the projects to the clients?
													</button>
												</h2>
												<div
													id="collapseThree"
													className="accordion-collapse collapse"
													aria-labelledby="headingThree"
													data-bs-parent="#accordionExample"
												>
													<div className="accordion-body">
														<strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the
														appropriate classNamees that we use to style each element. These classNamees control the overall appearance, as well as
														the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default
														variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the
														transition does limit overflow.
													</div>
												</div>
											</div>
											<div className="accordion-item">
												<h2 className="accordion-header" id="headingFour">
													<button
														className="accordion-button collapsed"
														type="button"
														data-bs-toggle="collapse"
														data-bs-target="#collapseFour"
														aria-expanded="false"
														aria-controls="collapseFour"
													>
														<span>04</span>
														What features are included in the free trial plan?
													</button>
												</h2>
												<div
													id="collapseFour"
													className="accordion-collapse collapse"
													aria-labelledby="headingFour"
													data-bs-parent="#accordionExample"
												>
													<div className="accordion-body">
														<strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the
														appropriate classNamees that we use to style each element. These classNamees control the overall appearance, as well as
														the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default
														variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the
														transition does limit overflow.
													</div>
												</div>
											</div>
											<div className="accordion-item">
												<h2 className="accordion-header" id="headingFive">
													<button
														className="accordion-button collapsed"
														type="button"
														data-bs-toggle="collapse"
														data-bs-target="#collapseFive"
														aria-expanded="false"
														aria-controls="collapseFive"
													>
														<span>05</span>
														What features are included in the free trial plan?
													</button>
												</h2>
												<div
													id="collapseFive"
													className="accordion-collapse collapse"
													aria-labelledby="headingFive"
													data-bs-parent="#accordionExample"
												>
													<div className="accordion-body">
														<strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the
														appropriate classNamees that we use to style each element. These classNamees control the overall appearance, as well as
														the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default
														variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the
														transition does limit overflow.
													</div>
												</div>
											</div>
											<div className="accordion-item">
												<h2 className="accordion-header" id="headingSix">
													<button
														className="accordion-button collapsed"
														type="button"
														data-bs-toggle="collapse"
														data-bs-target="#collapseSix"
														aria-expanded="false"
														aria-controls="collapseSix"
													>
														<span>06</span>
														What features are included in the free trial plan?
													</button>
												</h2>
												<div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
													<div className="accordion-body">
														<strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the
														appropriate classNamees that we use to style each element. These classNamees control the overall appearance, as well as
														the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default
														variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the
														transition does limit overflow.
													</div>
												</div>
											</div>
										</div>
										<div className="fqa-right">
											<div className="accordion-item">
												<h2 className="accordion-header" id="headingSeven">
													<button
														className="accordion-button collapsed"
														type="button"
														data-bs-toggle="collapse"
														data-bs-target="#collapseSeven"
														aria-expanded="false"
														aria-controls="collapseSeven"
													>
														<span>07</span>
														Why streamefy?
													</button>
												</h2>
												<div
													id="collapseSeven"
													className="accordion-collapse collapse"
													aria-labelledby="headingSeven"
													data-bs-parent="#accordionExample"
												>
													<div className="accordion-body">
														<strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the
														appropriate classNamees that we use to style each element. These classNamees control the overall appearance, as well as
														the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default
														variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the
														transition does limit overflow.
													</div>
												</div>
											</div>
											<div className="accordion-item">
												<h2 className="accordion-header" id="headingEight">
													<button
														className="accordion-button collapsed"
														type="button"
														data-bs-toggle="collapse"
														data-bs-target="#collapseEight"
														aria-expanded="false"
														aria-controls="collapseEight"
													>
														<span>08</span>
														How to create a project on this platform?
													</button>
												</h2>
												<div
													id="collapseEight"
													className="accordion-collapse collapse"
													aria-labelledby="headingEight"
													data-bs-parent="#accordionExample"
												>
													<div className="accordion-body">
														<strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the
														appropriate classNamees that we use to style each element. These classNamees control the overall appearance, as well as
														the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default
														variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the
														transition does limit overflow.
													</div>
												</div>
											</div>
											<div className="accordion-item">
												<h2 className="accordion-header" id="headingNine">
													<button
														className="accordion-button collapsed"
														type="button"
														data-bs-toggle="collapse"
														data-bs-target="#collapseNine"
														aria-expanded="false"
														aria-controls="collapseNine"
													>
														<span>09</span>
														How can we deliver the projects to the clients?
													</button>
												</h2>
												<div
													id="collapseNine"
													className="accordion-collapse collapse"
													aria-labelledby="headingEight"
													data-bs-parent="#accordionExample"
												>
													<div className="accordion-body">
														<strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the
														appropriate classNamees that we use to style each element. These classNamees control the overall appearance, as well as
														the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default
														variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the
														transition does limit overflow.
													</div>
												</div>
											</div>
											<div className="accordion-item">
												<h2 className="accordion-header" id="headingTen">
													<button
														className="accordion-button collapsed"
														type="button"
														data-bs-toggle="collapse"
														data-bs-target="#collapseTen"
														aria-expanded="false"
														aria-controls="collapseTen"
													>
														<span>10</span>
														What features are included in the free trial plan?
													</button>
												</h2>
												<div
													id="collapseTen"
													className="accordion-collapse collapse"
													aria-labelledby="headingNine"
													data-bs-parent="#accordionExample"
												>
													<div className="accordion-body">
														<strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the
														appropriate classNamees that we use to style each element. These classNamees control the overall appearance, as well as
														the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default
														variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the
														transition does limit overflow.
													</div>
												</div>
											</div>
											<div className="accordion-item">
												<h2 className="accordion-header" id="headingElven">
													<button
														className="accordion-button collapsed"
														type="button"
														data-bs-toggle="collapse"
														data-bs-target="#collapseElven"
														aria-expanded="false"
														aria-controls="collapseElven"
													>
														<span>11</span>
														What features are included in the free trial plan?
													</button>
												</h2>
												<div
													id="collapseElven"
													className="accordion-collapse collapse"
													aria-labelledby="headingElven"
													data-bs-parent="#accordionExample"
												>
													<div className="accordion-body">
														<strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the
														appropriate classNamees that we use to style each element. These classNamees control the overall appearance, as well as
														the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default
														variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the
														transition does limit overflow.
													</div>
												</div>
											</div>
											<div className="accordion-item">
												<h2 className="accordion-header" id="headingTwelve">
													<button
														className="accordion-button collapsed"
														type="button"
														data-bs-toggle="collapse"
														data-bs-target="#collapseTwelve"
														aria-expanded="false"
														aria-controls="collapseTwelve"
													>
														<span>12</span>
														What features are included in the free trial plan?
													</button>
												</h2>
												<div
													id="collapseTwelve"
													className="accordion-collapse collapse"
													aria-labelledby="headingTwelve"
													data-bs-parent="#accordionExample"
												>
													<div className="accordion-body">
														<strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the
														appropriate classNamees that we use to style each element. These classNamees control the overall appearance, as well as
														the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default
														variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the
														transition does limit overflow.
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="trial-free">
					<div className="container">
						<div className="trial-free-inner">
							<div className="trial-left">
								<h2>Start your free trial today !</h2>
								<p>Create and deliver projects effortlessly with our all-in-one platform. We make your project easy, from start to finish.</p>
								<a href="https://app.streamefy.com/?tabId=1" className="cmn-btn">
									<span>
										<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M8.2821 16L15.7819 8.52686C15.9216 8.38684 16 8.19743 16 8C16 7.80257 15.9216 7.61316 15.7819 7.47314L8.2821 -3.3736e-07L7.22462 1.05371L13.442 7.24895L-3.16862e-07 7.24895L-3.82194e-07 8.74358L13.442 8.74358L7.22462 14.9388L8.2821 16Z"
												fill="white"
											></path>
										</svg>
										start free trial
									</span>
								</a>
							</div>
							<div className="trial-right">
								<img src={ptvBanner} />
							</div>
						</div>
					</div>
				</div>

				<div className="footer-sec" id="footer-main">
					<div className="container">
						<div className="footer-inner">
							<div className="footer-left">
								<div className="footer-left-inner">
									<div className="footer-nav">
										<h3>Quick Links</h3>
										<ul className="nav-list">
											<li>
												{/* <a href="javascript:void(0)">Features</a> */}
												<Link className="nav-link" to="you-get-access" smooth={true} duration={200}>
													Features
												</Link>
											</li>
											<li className="nav-item">
												<Link className="nav-link" to="pricing-section" smooth={true} duration={200}>
													Pricing
												</Link>
											</li>
											<li className="nav-item">
												<Link className="nav-link" to="footer-main" smooth={true} duration={200}>
													Contact us
												</Link>
											</li>
										</ul>
									</div>
									<div className="footer-nav">
										<h3>Follow Us On</h3>
										<ul className="nav-list social">
											<li>
												<a href="javascript:void(0)">
													<div className="icon-footer">
														<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path
																d="M7.8 2H16.2C19.4032 2 22 4.59675 22 7.8V16.2C22 19.4033 19.4032 22 16.2 22H7.8C4.59675 22 2 19.4033 2 16.2V7.8C2 4.59675 4.59675 2 7.8 2ZM7.6 3.99998C5.61177 3.99998 4 5.61176 4 7.59998V16.4C4 18.3882 5.61177 20 7.6 20H16.4C18.3882 20 20 18.3882 20 16.4V7.59998C20 5.61176 18.3882 3.99998 16.4 3.99998H7.6ZM17.25 5.49998C17.9404 5.49998 18.5 6.05963 18.5 6.74998C18.5 7.44034 17.9404 7.99998 17.25 7.99998C16.5596 7.99998 16 7.44034 16 6.74998C16 6.05963 16.5596 5.49998 17.25 5.49998ZM12 6.99998C14.7614 6.99998 17 9.23856 17 12C17 14.7614 14.7614 17 12 17C9.23857 17 7 14.7614 7 12C7 9.23856 9.23857 6.99998 12 6.99998ZM12 8.99998C10.3431 8.99998 9 10.3432 9 12C9 13.6569 10.3431 15 12 15C13.6569 15 15 13.6569 15 12C15 10.3432 13.6569 8.99998 12 8.99998Z"
																fill="white"
															/>
														</svg>
													</div>
													instagram
												</a>
											</li>
											<li>
												<a href="javascript:void(0)">
													<div className="icon-footer">
														<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path
																d="M22.4592 6.01238C21.6896 6.35373 20.8624 6.58442 19.9944 6.68815C20.8803 6.15701 21.5609 5.31598 21.8813 4.31378C21.052 4.80564 20.1336 5.16278 19.156 5.3552C18.3732 4.52112 17.2579 4 16.0235 4C13.6534 4 11.7317 5.92147 11.7317 8.29155C11.7317 8.6279 11.7697 8.95546 11.8429 9.2696C8.27609 9.0906 5.11375 7.38203 2.99709 4.78551C2.62765 5.41935 2.41601 6.15656 2.41601 6.94309C2.41601 8.43204 3.17364 9.74563 4.32523 10.5153C3.62179 10.4929 2.95996 10.2999 2.38139 9.97846C2.38098 9.99639 2.38098 10.0143 2.38098 10.0324C2.38098 12.1118 3.86033 13.8463 5.82359 14.2406C5.46349 14.3387 5.08434 14.3912 4.69294 14.3912C4.4164 14.3912 4.14755 14.3642 3.88546 14.3142C4.43161 16.0191 6.01653 17.26 7.89454 17.2945C6.42576 18.4457 4.57527 19.1318 2.56453 19.1318C2.21812 19.1318 1.87651 19.1114 1.54077 19.0717C3.44003 20.2894 5.69591 21 8.1195 21C16.0134 21 20.3302 14.4605 20.3302 8.78918C20.3302 8.60314 20.326 8.41805 20.3177 8.23395C21.1563 7.62886 21.8839 6.87302 22.4592 6.01238Z"
																fill="white"
															/>
														</svg>
													</div>
													Twitter
												</a>
											</li>
											<li>
												<a href="javascript:void(0)">
													<div className="icon-footer">
														<svg width="13" height="25" viewBox="0 0 13 25" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path
																d="M8.66667 14.375H11.7619L13 9.375H8.66667V6.875C8.66667 5.5875 8.66667 4.375 11.1429 4.375H13V0.175C12.5964 0.12125 11.0723 0 9.46276 0C6.10133 0 3.71429 2.07125 3.71429 5.875V9.375H0V14.375H3.71429V25H8.66667V14.375Z"
																fill="white"
															/>
														</svg>
													</div>
													Facebook
												</a>
											</li>
										</ul>
									</div>
								</div>
								<h2>Contact Us</h2>
							</div>
							<div className="footer-right">
								<h4>Fill the below form to get in touch with us</h4>
								<form onSubmit={formik.handleSubmit}>
									<div className="footer-form">
										<div className="form-group">
											<div className="form-field">
												<svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path
														d="M6 7.5C7.89375 7.5 9.42857 5.9332 9.42857 4C9.42857 2.0668 7.89375 0.5 6 0.5C4.10625 0.5 2.57143 2.0668 2.57143 4C2.57143 5.9332 4.10625 7.5 6 7.5ZM8.4 8.375H7.95268C7.35804 8.65391 6.69643 8.8125 6 8.8125C5.30357 8.8125 4.64464 8.65391 4.04732 8.375H3.6C1.6125 8.375 0 10.0211 0 12.05V12.1106C0 12.8352 0.575893 13.4231 1.28571 13.4231H10.7143C11.4241 13.4231 12 12.8352 12 12.1106V12.05C12 10.0211 10.3875 8.375 8.4 8.375Z"
														fill="#C1C1C1"
													/>
												</svg>
												<input
													type="text"
													name="name"
													placeholder="First Name*"
													value={formik.values.name}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
												/>
												{formik.touched.name && formik.errors.name ? <div className="error">{formik.errors.name}</div> : null}
											</div>
											<div className="form-field">
												<svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path
														d="M6 7.5C7.89375 7.5 9.42857 5.9332 9.42857 4C9.42857 2.0668 7.89375 0.5 6 0.5C4.10625 0.5 2.57143 2.0668 2.57143 4C2.57143 5.9332 4.10625 7.5 6 7.5ZM8.4 8.375H7.95268C7.35804 8.65391 6.69643 8.8125 6 8.8125C5.30357 8.8125 4.64464 8.65391 4.04732 8.375H3.6C1.6125 8.375 0 10.0211 0 12.05V12.1106C0 12.8352 0.575893 13.4231 1.28571 13.4231H10.7143C11.4241 13.4231 12 12.8352 12 12.1106V12.05C12 10.0211 10.3875 8.375 8.4 8.375Z"
														fill="#C1C1C1"
													/>
												</svg>
												<input
													type="text"
													name="lastname"
													placeholder="Last Name*"
													value={formik.values.lastname}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
												/>
												{formik.touched.lastname && formik.errors.lastname ? <div className="error">{formik.errors.lastname}</div> : null}
											</div>
										</div>
										<div className="form-group">
											<div className="form-field">
												{/* <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path
														d="M4.18673 0.895651C3.99123 0.422216 3.47582 0.170226 2.98327 0.30513L0.74899 0.916014C0.307213 1.03819 0 1.44036 0 1.89852C0 8.19571 5.09313 13.3017 11.3745 13.3017C11.8315 13.3017 12.2327 12.9937 12.3545 12.5508L12.9639 10.3109C13.0984 9.8171 12.8471 9.3004 12.3748 9.1044L9.93745 8.08626C9.5236 7.91318 9.04374 8.03281 8.76192 8.38152L7.73618 9.63638C5.94876 8.78878 4.50156 7.33793 3.65609 5.546L4.90779 4.52023C5.25563 4.23515 5.37496 3.75662 5.20231 3.34173L4.18673 0.898196V0.895651Z"
														fill="#C1C1C1"
													/>
												</svg> */}
												{/* <input
													type="tel"
													name="phoneNumber"
													placeholder="Phone Number*"
													value={formik.values.phoneNumber}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
												/> */}
												<PhoneInputComponent
													value={formik.values.phoneNumber}
													onFocus={(e) => formik.setFieldTouched('phoneNumber', true)}
													onChange={(phone) => formik.setFieldValue('phoneNumber', phone)}
													//onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													inputProps={{ name: 'phoneNumber', required: true, placeholder: 'Phone number ' }}
												/>
												{formik.touched.phoneNumber && formik.errors.phoneNumber ? <div className="error">{formik.errors.phoneNumber}</div> : null}
											</div>
											<div className="form-field">
												<svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path
														d="M13 11.7811C13 12.454 12.4541 12.9999 11.7812 12.9999H1.21875C0.545898 12.9999 4.629e-08 12.454 4.629e-08 11.7811V5.09576C-5.09215e-05 4.91082 0.0419874 4.72829 0.122931 4.56201C0.203874 4.39572 0.321599 4.25004 0.467188 4.13599C1.09941 3.64087 1.62246 3.23716 4.63633 1.05103C5.06289 0.741264 5.91094 -0.00775941 6.5 -0.000142219C7.08906 -0.0102985 7.93711 0.741264 8.36367 1.05103C11.3775 3.23716 11.9006 3.64087 12.5328 4.13599C12.6784 4.25004 12.7961 4.39572 12.8771 4.56201C12.958 4.72829 13.0001 4.91082 13 5.09576V11.7811ZM11.3318 6.78931C11.2658 6.69537 11.1363 6.67251 11.0449 6.74107C10.466 7.17017 9.63574 7.77447 8.36367 8.69869C7.93711 9.01099 7.08906 9.76001 6.5 9.74986C5.91094 9.75747 5.06289 9.01099 4.63633 8.69869C3.36426 7.77447 2.53652 7.17017 1.95508 6.74107C1.86367 6.67251 1.73164 6.69537 1.66816 6.78931L1.43711 7.12447C1.4069 7.16782 1.39478 7.22124 1.40331 7.27339C1.41185 7.32553 1.44036 7.3723 1.48281 7.40376C2.06426 7.83287 2.89199 8.43716 4.15645 9.3563C4.67188 9.73208 5.59102 10.57 6.5 10.5649C7.40645 10.57 8.32812 9.73208 8.84355 9.3563C10.1105 8.43716 10.9357 7.83287 11.5172 7.40376C11.5596 7.3723 11.5882 7.32553 11.5967 7.27339C11.6052 7.22124 11.5931 7.16782 11.5629 7.12447L11.3318 6.78931Z"
														fill="#A9A9A9"
													/>
												</svg>
												<input
													type="email"
													name="email"
													placeholder="Email address*"
													value={formik.values.email}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
												/>
												{formik.touched.email && formik.errors.email ? <div className="error">{formik.errors.email}</div> : null}
											</div>
										</div>
										<div className="form-group full">
											<div className="form-field">
												<svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path
														fillRule="evenodd"
														clipRule="evenodd"
														d="M0.708496 2.17328C0.708496 1.62752 0.929746 1.10411 1.32357 0.718195C1.7174 0.332282 2.25154 0.115479 2.8085 0.115479H12.6085C13.1655 0.115479 13.6996 0.332282 14.0934 0.718195C14.4872 1.10411 14.7085 1.62752 14.7085 2.17328V9.03263C14.7085 9.57839 14.4872 10.1018 14.0934 10.4877C13.6996 10.8736 13.1655 11.0904 12.6085 11.0904H4.6754C4.52394 11.0904 4.37656 11.1386 4.2554 11.2276L1.8285 13.0111C1.7245 13.0875 1.60083 13.134 1.47136 13.1455C1.34189 13.1569 1.21172 13.1328 1.09545 13.0758C0.979172 13.0189 0.881385 12.9313 0.813041 12.8229C0.744696 12.7146 0.708496 12.5897 0.708496 12.4623V2.17328ZM4.2085 2.17328C4.02284 2.17328 3.8448 2.24555 3.71352 2.37419C3.58225 2.50283 3.5085 2.6773 3.5085 2.85922C3.5085 3.04114 3.58225 3.21561 3.71352 3.34425C3.8448 3.47288 4.02284 3.54515 4.2085 3.54515H11.2085C11.3941 3.54515 11.5722 3.47288 11.7035 3.34425C11.8347 3.21561 11.9085 3.04114 11.9085 2.85922C11.9085 2.6773 11.8347 2.50283 11.7035 2.37419C11.5722 2.24555 11.3941 2.17328 11.2085 2.17328H4.2085ZM4.2085 4.91702C4.02284 4.91702 3.8448 4.98929 3.71352 5.11793C3.58225 5.24657 3.5085 5.42104 3.5085 5.60296C3.5085 5.78488 3.58225 5.95935 3.71352 6.08799C3.8448 6.21662 4.02284 6.28889 4.2085 6.28889H11.2085C11.3941 6.28889 11.5722 6.21662 11.7035 6.08799C11.8347 5.95935 11.9085 5.78488 11.9085 5.60296C11.9085 5.42104 11.8347 5.24657 11.7035 5.11793C11.5722 4.98929 11.3941 4.91702 11.2085 4.91702H4.2085ZM4.2085 7.66076C4.02284 7.66076 3.8448 7.73303 3.71352 7.86167C3.58225 7.9903 3.5085 8.16477 3.5085 8.3467C3.5085 8.52862 3.58225 8.70309 3.71352 8.83173C3.8448 8.96036 4.02284 9.03263 4.2085 9.03263H7.0085C7.19415 9.03263 7.3722 8.96036 7.50347 8.83173C7.63475 8.70309 7.7085 8.52862 7.7085 8.3467C7.7085 8.16477 7.63475 7.9903 7.50347 7.86167C7.3722 7.73303 7.19415 7.66076 7.0085 7.66076H4.2085Z"
														fill="#C1C1C1"
													/>
												</svg>
												<textarea
													name="message"
													placeholder="Message here"
													value={formik.values.message}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
												/>
											</div>
										</div>
										<button type="submit" className="cmn-btn">
											<span>
												<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path
														d="M8.2821 16L15.7819 8.52686C15.9216 8.38684 16 8.19743 16 8C16 7.80257 15.9216 7.61316 15.7819 7.47314L8.2821 -3.3736e-07L7.22462 1.05371L13.442 7.24895L-3.16862e-07 7.24895L-3.82194e-07 8.74358L13.442 8.74358L7.22462 14.9388L8.2821 16Z"
														fill="white"
													></path>
												</svg>
												Submit
											</span>
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>

				<div className="copy-right-footer">
					<div className="container">
						<div className="copy-right-foote-inner">
							<span>@2024 streamefy, All Rights Reserved</span>
							<ul>
								<li>
									<a href="javascript:void(0)">Terms of Use</a>
									<span>|</span>
								</li>
								<li>
									<a href="javascript:void(0)">Privacy Policy</a>
									<span>|</span>
								</li>
								<li>
									<a href="javascript:void(0)">Cookie Policy</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}
