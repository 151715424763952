import React from 'react';
import logo from './logo.svg';
import './App.css';

import LandingPage from './pages/landingPage/Index';
import Header from './components/header/Index';
import { BrowserRouter } from 'react-router-dom';

function App() {
	return (
		<>
			<BrowserRouter>
			<Header />
				<LandingPage />
				</BrowserRouter>
		</>
	);
}

export default App;
